{
	"404": {
		"label": {
			"message": "The page you requested no longer exists.",
			"support": "support",
			"support_message": "If you have any questions feel free to contact"
		}
	},
	"2fa": {
		"label": {
			"2fa_enabled_message": "Two Factor Authentication is enabled in your account.",
			"confirm_disable_2fa_message": "Are you sure you want to Disable Two-Factor Authentication ?",
			"disable": "Disable",
			"disable_2fa": "Disable Two-Factor Authentication",
			"enable_2fa": "Enable Two-Factor Authentication",
			"enter_code_message": "Enter the code generated by your authenticator app.",
			"enter_recovery_code": "Enter Recovery Code",
			"generate_qrcode": "Generate New Recovery Code",
			"recovered_message": "Your account recovered successfully, Please re-activate 2FA from settings.",
			"step1_message": "Two-factor authentication (2FA) provides an extra layer of security for your account. When 2FA is enabled, you will be required to enter a unique code in addition to your password when logging in. To enable 2FA, please click on below button:",
			"step2_message1": "Download an authenticator app on your mobile device, such as Google Authenticator.",
			"step2_message2": "Scan the QR code below using the authenticator app.",
			"step2_message3": "The app will generate a unique code. Enter this code in the field below to verify.",
			"step3_message1": "Below is recovery code for your account, please store it at secure place, it will help recover your account in case of loss device.",
			"step3_message2": "Recovery code will disappears after copy, you can generate new recovery code any time from here.",
			"two_factor_authentication": "Two-Factor Authentication",
			"use_a_recovery_code": "Use a recovery code",
			"use_an_authentication_code": " Use an authentication code",
			"users_2fa_disabled_message": "Two Factor Authentication is disabled in this account.",
			"users_2fa_enabled_message": "Two Factor Authentication is enabled in this account.",
			"verify": "Verify",
			"verify_your_identity": "Verify Your Identity"
		}
	},
	"ACCOUNT_NOT_FOUND": "Account not found",
	"BUSINESS_URL_NOT_FOUND": "Business URL not found.",
	"CHECKOUT_AND_PLATFORM_DOMAIN_SAME": "Checkout and platform domain can not be the same",
	"CUSTOMER_NOT_AUTHORIZED": "You are Not Authorized To get Customers Details.",
	"CUSTOMER_NOT_FOUND": "Customer not found",
	"DNS_LOOKUP_FAILED": "Unable to locate your cname on hosting provider",
	"DOMAIN_IS_ALREADY_IN_USE": "Domain is already in use",
	"Days": "Days",
	"FAILED_CAPTCHA": "Please try again!",
	"JWT_TOKEN_FAILED": "The session expired, please login again.",
	"Months": "Months",
	"NOT_AUTHORIZED_TO_SUBSCRIPTION": "You Are Not Allowed to Subscribe To This Plan.",
	"PLAN_DOES_NOT_EXIST": "Subscription Plan Does Not Exist.",
	"SUBSCRIPTION_ALREADY_ACTIVE": "Requested Subscription Plan For Upgrade Is Already Active.",
	"SYNC_WITH_STRIPE": "Please sync with stripe once to cancel your subscription.",
	"UPDATE_YOUR_PLAN": "Your current plan does not support this feature. Please upgrade to enable the feature.",
	"USER_NOT_BELONGS_TO_BUSINESS": "User is not belongs to this business",
	"Weeks": "Weeks",
	"Year": "Year",
	"Your card was declined. Your request was in test mode, but used a non test (live) card. For a list of valid test cards, visit: https://stripe.com/docs/testing.": "Your card is declined please enter valid card number. ",
	"a_temporary_password_has_been_sent_to": "A temporary password has been sent to",
	"about_us": "About Us",
	"account": "Account",
	"account_details": "Account Details",
	"account_information": "Account Information",
	"account_setting": "Account Settings",
	"account_signup": "Account Signup",
	"action": "Action",
	"activate_my_account": "Activate My Account",
	"active": "active",
	"activity": "Activity",
	"add": "Add",
	"add_new": "Add New",
	"add_pay_now_button": "Add Pay Now Button",
	"add_refund": "Add Refund",
	"all_fields_are_required": "All fields are required.",
	"amount": "Amount",
	"and_last_modified": "And last modified",
	"announcements": {
		"label": {
			"Are_you_sure_you_want_to_delete_this_announcement": "Are you sure you want to delete this announcement?",
			"announcement_date": "Announcement date",
			"announcement_publish": "Announcement publish",
			"announcement_title": "Announcements",
			"no_announcement_is_available": "No announcement is available.",
			"view_all": "View all"
		}
	},
	"auth/account-disabled": "Your account is disabled, please contact admin.",
	"auth/app-deleted": "It seems like app is deleted.",
	"auth/app-not-authorized": "It seems like app is not authorized.",
	"auth/argument-error": "Argument is not valid.",
	"auth/email-already-in-use": "The email address is already in use by another account.",
	"auth/internal-error": "Too many attempts try later",
	"auth/invalid-api-key": "Invalid api key.",
	"auth/invalid-email": "The email address is improperly formatted.",
	"auth/invalid-user-token": "Invalid user token.",
	"auth/member-login-disable": "Business that you are trying to login to, does not support member login anymore, please contact admin",
	"auth/network-request-failed": "Network request failed.",
	"auth/operation-not-allowed": "This operation not allowed.",
	"auth/requires-recent-login": "Requires recent login.",
	"auth/too-many-requests": "Too many unsuccessful login attempts. Please try resetting the password.",
	"auth/unauthorized-domain": "Unauthorized domain please check your domain.",
	"auth/user-disabled": "This User is disabled please contact us.",
	"auth/user-not-found": " There is no user record corresponding to this identifier. The user may have been deleted.",
	"auth/user-token-expired": "This User token expired.",
	"auth/weak-password": "Password should be at least 6 characters",
	"auth/web-storage-unsupported": "Web storage unsupported.",
	"auth/wrong-authentication-code": "Invalid code",
	"auth/wrong-password": "The password is invalid or the user does not have a password.",
	"bank_transfer": "Bank Transfer",
	"begin_accepting_payments": "Begin Accepting Payments in less than 60 seconds",
	"billing": "Billing",
	"billings": {
		"error": {},
		"label": {
			"download_as_pdf": "Download As PDF",
			"issued_refund_on": "Issued Refund on",
			"off": "OFF",
			"payment_for_one_time": "Payment for one time",
			"print": "Print",
			"recurring_payment_for_subscription": "Recurring payment for subscription",
			"sub_total": "Sub Total",
			"thank_you_for_your_business": "Thank you for your business!",
			"total_paid": "Total Paid"
		},
		"success": {}
	},
	"brand": "Brand",
	"cancel": "Cancel",
	"cancel_subscription": "Cancel Subscription",
	"card": "Card",
	"card_info": "Card Information",
	"card_info_required": "Card Information is required",
	"card_information": "Card Information",
	"card_number": "Card Number",
	"clone_invoice": {
		"error": {},
		"label": {
			"are_you_sure_want_to_clone_invoice": "Are you sure to clone this Invoice? (Coupons, File Attachment, and Additional fields will be excluded.)",
			"clone_invoice": "Clone Invoice"
		},
		"success": {}
	},
	"close": "Close",
	"connect_account": "Connect To Stripe",
	"connect_your_account": "Connect Your Account To Stripe",
	"country": "Country",
	"coupon": {
		"error": {
			"coupon_was_expired": "Coupon was expired!",
			"invalid_coupon_code": "Invalid coupon code",
			"max_redeem_limit_exists": "Max redeem limit exists"
		},
		"label": {
			"apply_coupon": "Apply coupon",
			"enter_coupon_code": "Enter coupon code...",
			"remove_coupon": "Remove coupon"
		}
	},
	"create_new_invoice": "Create new invoice",
	"credit_card": "Credit Card",
	"cus_payments": {
		"label": {
			"paid_invoices": "Paid Invoices",
			"open_invoices": "Open Invoices",
			"payment_charge_on": "Payment charge on",
			"your_payment_method": "Your Payment Methods"
		},
		"success": {}
	},
	"custom_plans": {
		"error": {
			"decimal_point_are_not_allowed": "Decimal value is not allowed on the selected currency.",
			"delay_can_not_be_greater_than_1_year_equivalent": "Delay can not be greater than 1 year equivalency.",
			"enter_amount": "Please enter an amount.",
			"enter_valid_delay": "Please enter a valid delay value.",
			"maximum_two_decimal_points_allowed": "Please enter an amount with a maximum of two decimal points.",
			"please_add_two_or_more_plan": "Please add two or more plans.",
			"should_be_equivalent_to_1_usd": "The amount cannot be less than $1.00 USD equivalency.",
			"should_be_less_than_999999.99": "Please enter an amount value smaller than 999999.99."
		},
		"label": {
			"amount": "Amount",
			"are_you_sure_you_want_to_cancel_plan": "Are you sure you want to cancel",
			"cancelled_on": "canceled on",
			"charge_amount": "Charge amount",
			"custom": "custom plan",
			"day": "day",
			"delay": "Delay",
			"delay_details": "Add value you want to wait before next payment.",
			"delay_interval": "Delay Interval",
			"delay_interval_details": "Select delay period option",
			"for": "for",
			"immediately": "Immediately",
			"invoice_charge_for": "Invoice charge for",
			"invoice_paid_on": "Invoice paid on ",
			"invoice_payment_failed_on": "Invoice payment failed on",
			"invoice_will_be_charge_on": "Invoice will be charged on",
			"is_cancelled": "is canceled.",
			"month": "month",
			"plan_will_end": "Plan will end",
			"plan_will_end_on": "Plan will end on",
			"wait_for": "Wait for",
			"week": "week",
			"year": "year"
		}
	},
	"customer": "Customer",
	"customer_details": "Customer Details",
	"customers": {
		"error": {},
		"label": {
			"cancel_subscription": "Cancel Subscription",
			"credit_cards": "Credit Cards",
			"customer": "Customer",
			"customer_details": "Customer Details",
			"customer_internal_notes": "Customer internal notes",
			"get_update_payment_method_link": "Update payment link",
			"invoices": "Invoices",
			"lifetime_revenue": "Lifetime revenue",
			"lifetime_revenue_from_customer": "Lifetime revenue from customer",
			"net_revenue": "Net revenue",
			"notes": "Notes",
			"past_payments": "Past payments",
			"payment_methods": "Payment Methods",
			"plan_title": "Plan Title",
			"please_wait": "Please wait!",
			"recent_payments": "Recent Payments",
			"share_this_update_payment_method_link_to_customer": "Share the below link with the customer to update their payment method.",
			"subscription": "Subscriptions",
			"tag_tooltip": "Press enter key after typing the tag name.",
			"total_refund": "Total refund",
			"total_revenue": "Total revenue",
			"upcoming_payments": "Upcoming payments",
			"update": "Update",
			"update_customer": "Update Customer",
			"update_payment_method_link": "Update Payment Link",
			"your_customers": "Your Customers",
			"confirm_before_invite": "Please confirm before sending invite?"
		},
		"success": {
			"your_bank_account_is_updated": "Your bank account is updated",
			"your_card_is_updated": "Your card is updated",
			"your_payment_method_is_updated": "Your payment method is updated"
		}
	},
	"daily": "Daily",
	"data": "Data",
	"date": {
		"format": {
			"long": "MMM Do, YYYY, h:mm:ss a",
			"medium": "DD-MM-YYYY",
			"short": "MM-DD-YY"
		}
	},
	"day_free_trial": "Days Free Trial",
	"day_trial": "Day trial",
	"days_ago": "days ago",
	"days_free_trial": " Days Free Trial!",
	"default_currency": "Default Currency",
	"default_redirect_url": "Default Redirect URL",
	"disconnect": "Disconnect",
	"display_name": "Display Name",
	"domain": "Domain",
	"download_as_pdf": "Download As PDF",
	"draft": "Draft",
	"email_is_required": "Email is required",
	"expiry": "Expiry",
	"expiry_date": "Expiry Date",
	"failed_payments": "Failed Payments",
	"full_name": "Full Name",
	"general": "General",
	"global": {
		"error": {
			"duplicate_account": "This stripe account is already connected with other email address.",
			"permission_denied": "Permission Denied!",
			"please_contact_your_support_team": "There was problem connecting your stripe account please contact support team.",
			"please_enter_valid_email": "Please enter valid email.",
			"please_enter_valid_name": "Please enter valid name.",
			"please_enter_valid_password": "Please enter valid password.",
			"please_try_again": "Something went wrong please try again.",
			"there_was_an_error_cancelling_the_subscription": "There was an error cancelling the subscription.",
			"user_connect_with_stripe": "User not connected with stripe account"
		},
		"label": {
			"account_holder_type": "Account Holder Type",
			"account_type": "Account Type",
			"action": "Action",
			"add": "Add",
			"address": "Address",
			"amount": "Amount",
			"and": " and ",
			"announcements": "Announcements",
			"app_store": "App Store",
			"are_you_sure_to_send_invoice_to_email": "Please confirm before sending invoice?",
			"are_you_sure_you_want_to_delete_this_user": "Are you sure you want to delete this user?",
			"are_you_sure_you_want_to_perform_this_operation": "Are you sure you want to perform this operation ?",
			"are_you_sure_you_want_to_remove_this_account": "Are you sure you want to remove this account ?",
			"are_you_sure_you_want_to_remove_this_card": "Are you sure you want to remove this card ?",
			"are_you_sure_you_want_to_remove_this_field": "Are you sure you want to remove this field?",
			"are_you_sure_you_want_to_remove_this_invoice": "Are you sure you want to remove this invoice?",
			"are_you_sure_you_want_to_remove_this_payment_method": "Are you sure you want to remove this payment method?",
			"are_you_sure_you_want_to_remove_this_tax": "Are you sure you want to remove this tax?",
			"are_you_sure_you_want_to_unarchive_this_tax": "Are you sure you want to unarchive this tax?",
			"bank_account_number": "Bank Account Number",
			"bank_accounts": "Bank Accounts",
			"bank_name": "Bank Name",
			"bill_to": "Bill to",
			"billing_address": "Billing address",
			"billings": "Billings",
			"business_invalid": "No active account is associated with this URL",
			"cancel": "Cancel",
			"card": "Card",
			"card_information": "Card Details",
			"card_number": "Card number",
			"checkout_host_name": "Custom hostname for checkout app",
			"checkout_subdomain": "Checkout URL ",
			"city": "City",
			"close": "Close",
			"close_modal": "Close Modal",
			"complete3DS": "Complete 3D Secure",
			"contact_support": "Something went wrong! Please contact support.",
			"country": "Country",
			"created": "Created",
			"currency": "Currency",
			"custom": "custom",
			"customer": "Customer",
			"customers": "Customers",
			"date": "Date",
			"default": "Default",
			"delete": "Delete",
			"description": "Description",
			"disconnect_stripe_statement": "Are you sure you want to disconnect ?",
			"display_free_plan": "Display free plan",
			"domain": "Domain",
			"domain_White_label": "White label checkout page",
			"domain_checkout_page": "You can use your own checkout page.",
			"domain_service_provider": "Before verifying please login to your domain hosting service provider and enter the below DNS entries !",
			"donation": "Donation",
			"download": "Download",
			"download_android_app": "Download android app.",
			"download_app": "Download App",
			"download_ios_app": "Download ios app.",
			"download_mobile_app": "Download mobile App",
			"edit": "Edit",
			"edit_bank_account": "Edit Bank Account",
			"edit_card": "Edit Card Details",
			"edit_sepa": "Edit SEPA Details",
			"email": "Email",
			"email_address": "Email Address",
			"end_date": "End Date",
			"expire": "Expire",
			"expiry_date": "Expiry date",
			"first_payment": "First payment",
			"forgot_password": "Forgot password?",
			"full_refund": "Full refund",
			"get_update_card_link": "Get Update Card Link",
			"get_update_payment_method_link": "Get update payment method link",
			"google_play": "Google Play",
			"i_agree_with_the": "I agree with the",
			"iban_number": "IBAN number",
			"infinite": "Infinite",
			"invoice": "Invoice",
			"invoice_title": "Invoice Title",
			"invoices": "Invoices",
			"last4": "Last4",
			"load_more": "Load More",
			"name": "Name",
			"new": "NEW",
			"next": "Next",
			"no_cards": "No Cards",
			"no_coming_payments": "No Upcoming Payments",
			"no_data_is_available": "No data is available",
			"no_payment_method": "No Payment Methods",
			"no_payments": "No payments",
			"no_subscriptions": "No subscriptions",
			"no_user_found": "No User Found",
			"notifications": "Notifications",
			"okay": "Okay",
			"onetime": "Onetime",
			"open_in_stripe": "Open in Stripe",
			"password": "Password",
			"confirmPassword": "Confirm Password",
			"payment": "Payment",
			"payment_failure_reason": "Payment Failure Reason",
			"payment_method": "Payment Methods",
			"payment_method_not_available": "Payment method details not available",
			"payments": "Payments",
			"plan": "Plan",
			"plan_name": "Plan Name",
			"platform_host_name": "Custom hostname for platform app",
			"please_downgrade_your_subscription_to_get_access": "Your current plan does not support this feature. Please downgrade to enable the feature.",
			"please_upgrade_your_subscription_to_get_access": "Your current plan does not support this feature. Please upgrade to enable the feature.",
			"powered_by": "Powered by",
			"profile_tag": "Profile Tag",
			"quick_view": "Quick View",
			"rate": "Rate",
			"recurring": "Recurring",
			"refund": "Refund",
			"refund_on": "Refund on ",
			"refunds": "Refunds",
			"region": "Region",
			"remove": "Remove",
			"resent": "Resent",
			"revenue": "Revenue",
			"role": "Role",
			"save": "Save",
			"search": "Search",
			"select_search _type": "Select search type",
			"send": "Send",
			"sepa_supported_country": "Select SEPA supported country",
			"settings": "Settings",
			"share_this_update_card_link": "Share This Update Card Link",
			"share_this_update_payment_method_link": "Share this update payment method link",
			"ship_to": "Ship to",
			"shipping_address": "Shipping Address",
			"show_success_message": "Successfully updated",
			"sign_in": "Sign In",
			"sign_up": "Sign Up",
			"single": "Onetime",
			"something_went_wrong": "Something went Wrong",
			"start_date": "Start Date",
			"started": "Started",
			"state": "State",
			"status": "Status",
			"stripe_account_id": "Stripe Account Id",
			"stripe_customer_id": "Stripe Customer Id",
			"stripe_sync_active": "Stripe Sync is Active",
			"subDomain": "Sub Domain",
			"subscriptions": "Subscriptions",
			"tag": "Tag",
			"tags": "Tags",
			"tax_name": "Tax Name",
			"tax_percent": "Tax Percent",
			"tax_placeholder": "VAT, GST/PST, TAX",
			"teams": "Teams",
			"terminate_account_confirm_Statement": "Rest is assured any active subscriptions will remain active in stripe. Are you sure you want to terminate account ?",
			"terminate_account_statement": "Your account was successfully canceled. Thanks for giving us a chance.",
			"terms_of_policy": "Terms and policy",
			"title": "Title",
			"todos": "Todos",
			"type": "Type",
			"up_coming_invoice_date": "Upcoming Invoice Date",
			"update": "Update",
			"update_card": "Save",
			"upgrade": "Upgrade",
			"upgrade_plan_name_price_period_dynamic": "Plan to upgrade to {{name}} (${{price}}/{{period}}) to enable a custom domain.",
			"upgrade_your_subscription": "This feature is not available with the current plan.",
			"verified": "Verified",
			"verify": "Verify",
			"view": "View",
			"view_customer_account": "View customer account",
			"with": " with ",
			"you_want_to_downgrade_your_subscription": "Do you want to downgrade your subscription?",
			"you_want_to_upgrade_your_subscription": "Do you want to upgrade your subscription?",
			"your_domain_details_updated_successfully": "Your Domain Details Updated Successfully !",
			"your_payments": "Your Payments",
			"your_tax_details_is_added": "Your tax details is added.",
			"your_tax_details_is_getting_added": "Your tax details is getting added!",
			"your_tax_details_is_getting_updated": "Your tax details is getting updated!",
			"your_tax_details_is_updated": "Your tax details is updated."
		}
	},
	"icon": "Icon",
	"incomplete_cvc": "Your card's security code is incomplete.",
	"incomplete_expiry": "Your card's expiration date is incomplete.",
	"incomplete_number": "Your card number is incomplete.",
	"incomplete_zip": "Your postal code is incomplete.",
	"integration_page": {
		"error": {},
		"label": {
			"integration": "Integrations"
		},
		"success": {}
	},
	"invalid_number": "Your card number is invalid.",
	"invoice": {
		"error": {
			"please_enter_button_text": "Please enter valid button text.",
			"this_field_is_required": "This field is required"
		},
		"label": {
			"one_time_setup_fees": "One time setup fees",
			"add_payment_button_on_your_website": "Add payment button on your website",
			"added_customers": "Added Customers",
			"allow_customer_for_subscription": "Allow customer to cancel/pause subscription",
			"and_last_modified": "and last modified",
			"assign_invoice_to_customer": "Assign Invoice to customer",
			"button_color": "Button Color",
			"button_text": "Button Text",
			"copied": "Copied!",
			"copy_to_clipboard": "Copy to clipboard",
			"coupon": "Coupon",
			"coupon_code": "Coupon Code",
			"coupons": "Coupons",
			"create_date": "Create Date",
			"create_new_invoice": "Create new invoice",
			"discount": "Discount",
			"download_invoice": "Download invoice",
			"duration": "Duration",
			"email_is_send_successful": "Email is send successful",
			"expire_date": "Expire Date",
			"forever": "Forever",
			"hover_color": "On Hover Button Color",
			"hover_text_color": "On Hover Text Color",
			"i_agree_with_the": "I agree with the",
			"if_enable_subscription_message": "If enable customer can cancel/pause subscription",
			"invoice_created": "Invoice created",
			"invoice_details": "Invoice Details",
			"invoice_metrics": "Invoice Metrics",
			"let_take_a_few_seconds_to_create_your_first_invoice": "Let take a few seconds to create your first invoice",
			"max_payments": "Max Payments",
			"modified": "Modified",
			"no_expire_date": "No Expire Date",
			"once": "Once",
			"one_time_set_up_fees": "One Time Set Up Fees",
			"pay_now": "Pay Now",
			"popup": "Popup",
			"preview": "Preview",
			"redemption_limit": "Redemption Limit",
			"redirect_to_invoice": "Redirect To Invoice",
			"repeating": "Repeating",
			"setup_fees": "Setup Fees",
			"share_this_payment_link": "Share this payment link",
			"successful_payments": "Successful Payments",
			"terms_of_service": "Terms of service",
			"text_color": "Text Color",
			"title": "Title",
			"total_pageviews": "Total Pageviews",
			"total_revenue": "Total Revenue",
			"total_revenue_without_tax": "Total Revenue (Without tax)",
			"trial_period": "Trial Period",
			"unique_visitors": "Unique Visitors",
			"view_invoice": "View Invoice",
			"website_integration": "Website Integration",
			"your_invoice_has_been_deleted": "Your invoice has been deleted",
			"your_invoices": "Your Invoices",
			"download_attachment_file": "Download Attachment File"
		},
		"success": {
			"new_invoice_has_been_saved": "New invoice has been saved."
		}
	},
	"invoice_created": "Invoice created",
	"invoice_details": "Invoice Details",
	"invoice_management": {
		"error": {
			"amount_can_not_be_less_than_1_usd_equivalency": "The amount cannot be less than ",
			"cannot_be_less_than_0": "Can not be less than 0",
			"cannot_be_less_than_1": "Can not be less than 1",
			"cannot_be_less_than_2": "Can not be less than 2",
			"cannot_be_more_than_100": "Can not be more than 100",
			"cannot_be_more_than_365": "Can not be more than 365",
			"cannot_be_more_than_999": "Cannot be More than 999",
			"cannot_be_more_than_invoice_price": "Can not be more than Invoice Price",
			"coupon_already_exist": "Coupon Already Exist with same code",
			"decimal_values_is_not_allowed_on_selected_currency": "Decimal value is not allowed on selected currency",
			"equivalency": "equivalency",
			"invalid_coupon_format": "Invalid Coupon Format",
			"maximum_size_20_MB": "Maximum size of pdf can be upto 20 Mb only.",
			"one": "1.00 ",
			"only_positive_value_allowed": "value can not be less than 1",
			"please_attach_file": "Please attach file",
			"please_enter_a_future_date": "Please Enter A Future Date",
			"please_enter_a_future_month": "Please Enter A Future Month",
			"please_enter_amount": "Please enter amount",
			"please_enter_amount_value_smaller_than_999999.99": "Please enter amount value smaller then : 999999.99",
			"please_enter_amount_with_maximum_two_decimal_points": "Please enter amount with maximum two decimal points",
			"please_enter_atleast_one_option": "Please enter at least one option",
			"please_enter_coupon_code": "Please enter coupon code",
			"please_enter_description": "Please enter description.",
			"please_enter_hidden_field_value": "Please enter hidden field value.",
			"please_enter_label_name": "Please enter label name",
			"please_enter_number_of_months": "Please enter number of months",
			"please_enter_one_time_setup_fees_value_smaller_than_999999.99": "Please enter one time setup fees value smaller then : 999999.99",
			"please_enter_percentage": "Please enter percentage",
			"please_enter_price_over_99_cents": "Please enter price over 99 cents.",
			"please_enter_product_name": "Please enter product name.",
			"please_enter_unique_option": "Please enter unique option ",
			"please_enter_valid_default_option": "Please enter valid default option",
			"please_enter_valid_tax_name": "Please enter valid tax name",
			"please_enter_valid_tax_percent": "Please enter valid tax percent",
			"please_enter_valid_trial_period": "Please enter valid trial period",
			"please_enter_valid_url": "Please enter valid URL.",
			"please_select_date_time_in_future": "Please enter date time in future",
			"please_upload_pdf_only": "Please upload PDF format file only",
			"problem_creating_invoice": "Problem Creating Invoice. Please contact support team!",
			"selected_tax_is_deactivated": "Selected tax is deactivated, Please select active tax",
			"their_is_no_tax_created_you_can_create_it_from_setting": "Their are no taxes set. You can create it from Settings.",
			"this_invoice_cannot__update": " This invoice can't update. Permission denied"
		},
		"label": {
			"Address_book": "Address book",
			"April": "April",
			"August": "August",
			"Calendar": "Calendar",
			"Checkbox": "Checkbox",
			"Comments": "Comments",
			"December": "December",
			"Dropdown": "Dropdown",
			"February": "February",
			"January": "January",
			"July": "July",
			"June": "June",
			"March": "March",
			"May": "May",
			"November": "November",
			"October": "October",
			"Radio": "Radio",
			"September": "September",
			"TextArea": "Text area",
			"Textfield": "Text field",
			"a_quick_description_of_your_product_or_service": "A quick description of your product or service",
			"a_unique_coupon_code": "A new coupon",
			"ach": "ACH",
			"add": "Add",
			"add_additional_fields": "Add Additional Fields",
			"add_coupon": "Add Coupon",
			"add_new_invoice": "Add New Invoice",
			"add_new_invoice_for_customer": "Add New Invoice for {{customerName}}",
			"add_option": "Add Option",
			"additional_fields": "Additional Fields",
			"additional_fields_can_be_used": "Additional Fields can be used to capture additional information from the user.",
			"address": "Address",
			"advanced_options": "Advanced options",
			"allow_onetime": "Allow One Time",
			"allow_recurring": "Allow Recurring",
			"amount": "Amount",
			"apply_coupon_for": "Apply coupon for",
			"attach_ebook": "Attach e-Book",
			"attach_ebook_tooltip": "This feature is for those who are selling digital products such as eBook. Your customer sees the download button after successful payment And receives the email as an attachment.",
			"biWeekly": "Bi-weekly",
			"biweekly": "Bi-weekly",
			"billing_address": "Billing Address",
			"business_consulting_service": "Business Consulting Service",
			"can_be_redeemed_for": "Can be redeemed for",
			"cancel": "Cancel",
			"check_to_set_it_as_default": "Check to set it as default",
			"collect_your_customer_information": "Collect your customer information and process their payment in seconds.",
			"coupon_code": "Coupon Code",
			"coupon_has_been_deleted": "Coupon has been deleted",
			"coupon_name": "Coupon Name",
			"coupon_remove_confirm": "Deleting this coupon will not affect discounts for customers who have already redeemed the coupon, but new redemptions of the coupon won’t be allowed. Are you sure you want to delete this coupon? This can’t be undone.",
			"creating_new_short_link": "Creating new short link...",
			"creating_new_subscription": "Creating a new subscription...",
			"currency": "Currency",
			"custom": "custom plan",
			"daily": "Daily",
			"decide_at_checkout": "Decide at Checkout",
			"default_option": "Default option",
			"description": "Description",
			"display_on_hidden_field": "Displayed on stripe metadata",
			"display_on_receipt": "Display on receipt",
			"display_on_receipt_field": "Display the value of this field in the receipt",
			"display_radio_label": "Displayed on receipt and stripe metadata",
			"do_you_want_to_add_a_one_off_charge_to_your_invoice": "Do you want to add a one off charge to your invoice?  (Optional)",
			"donation": "Donation",
			"download_pdf_file": "Would you like to download PDF file?",
			"drag_and_drop_here": "Drag and drop a PDF file here or click upload PDF file.",
			"drop_here": "Drop Here",
			"duration": "Duration",
			"edit": "Edit",
			"edit_additional_field": "Edit Additional field",
			"edit_coupon": "Edit Coupon",
			"email_attachment": "e-Mail Attachment",
			"email_default_subject": "Attached is your eBook",
			"email_notification": "Email Notification",
			"end_after_x_payments": "End After X Payments",
			"enter_default_option": "Enter default option",
			"enter_hidden_field_value": "Enter hidden field value",
			"enter_label_name": "Enter label name",
			"enter_number_of_days_before_the_first_payment_occurs": "Enter number of days before the first payment occurs",
			"enter_page_url_where_the_customer_will_be_redirected": "Enter page url where the customer will be redirected after a successful payment. (By default it will take them to a payment receipt page).",
			"enter_placeholder": "Enter placeholder",
			"enter_the_number_of_payments_you_want": "Enter the number of payments you want to be taken before the subscription cancel.",
			"enter_your_desirable_amount": "Enter your desirable amount (The currency symbol will change based on your selected currency).",
			"enter_your_invoice_name": "Enter your invoice name",
			"envelope": "Envelope",
			"every": "Every",
			"expiration_date_and_time_of_coupon": "Expiration date and time of coupon",
			"expire_date": "Expire Date",
			"file_delete_successfully": "File Removed successfully",
			"file_downloaded_successfully": "File downloaded successfully",
			"file_name": "File Name",
			"file_uploaded_successfully": "File uploaded successfully",
			"first": "First",
			"first_bill_date": "First Bill Date",
			"first_month_only": "First Month Only",
			"for_how_much_long_coupon_can_be_redeemed": "For how much long coupon can be redeemed",
			"forever": "Forever",
			"give_a_small_description": "Give a small description of your product so that your customers can easily recognize it.",
			"give_discount_in_amount": "Give Discount in amount",
			"give_discount_in_percent": "Give Discount in percent",
			"hidden": "Hidden",
			"hidden_text": "(Hidden)",
			"hide": "Hide",
			"how_much_are_you_charging": "How much are you charging",
			"is_this_is_a_one_time_product_or_a_recurring_product": "Is this is a one time product or a recurring product",
			"label_name": "Label Name",
			"limit_applies_across_all_customer": "This limit applies across customers so it won't prevent a single customer from redeeming multiple times.",
			"limit_total_number_redemptions": "Limit the total number of times this coupon can be redeemed",
			"month_regardless_of_time": "month(s) regardless of how many times subscription occurs.",
			"monthly": "Monthly",
			"months": "Months",
			"multiple_months": "Multiple Months",
			"name_of_your_product_or_service": "Name of your Product or Service",
			"no_coupons": "No Coupon",
			"no_of_times": "Enter limit count",
			"not_yet_set": "Not yet set",
			"number_of_quantity": "Number of Quantity",
			"of_the_month": "Of The Month",
			"of_the_year": "Of The Year",
			"offer_code": "OFFER-CODE",
			"one_time": "One Time",
			"one_time_setup_fees": "One time setup fees",
			"option": "option",
			"optional": "optional",
			"options": "Options",
			"other_options": "Other Options",
			"payment": "Payment Plan",
			"payment_information": "Payment Information",
			"payment_type": "Payment Type",
			"percent": "Percent",
			"placeholder": "Place holder",
			"please_create_new_invoice": "You can not update invoice type to Decide at Checkout please create a new invoice instead.",
			"please_enter_number_of_month": "Please enter number of months",
			"please_enter_price_over_99_cents": "Please enter price over 99 cents",
			"problem_downloading_file": "Problem downloading file",
			"problem_removing_file": "There is problem deleting file",
			"problem_uploading_file": "There is problem uploading file",
			"product_information": "Product Information",
			"quantity": "Quantity",
			"recurring": "Recurring",
			"redirect_after_payment": "Redirect After Payment",
			"remove": "Remove",
			"remove_uploaded_file": "Are you sure you want to remove this file?",
			"required": "Required",
			"required_field": "This field is required to be completed in order to checkout.",
			"save_and_email_invoice": "Save And Email Invoice",
			"save_invoice": "Save Invoice",
			"select_field_type": "Select field type",
			"select_icon": "Select icon",
			"select_theme": "Select Theme",
			"select_theme_for_your_invoice_app_to_look_elegant": "Select theme for your invoice app to look elegant.",
			"select_your_default_currency": "Select your default currency",
			"select_your_desired_payment_interval": "Select your desired payment interval",
			"select_your_first_bill_date": "Select your first bill date",
			"sepa_debit": "SEPA debit",
			"set_ach_1": "ACH payments take up to 5 business days to receive acknowledgment of their success or failure. Because of this, ACH payments take up to 7 business days to be reflected in your available Stripe balance.",
			"set_ach_2": "You can only accept funds in USD and only from U.S. bank accounts. In addition, your account must have a U.S./USD bank account to accept ACH payments.",
			"set_default": "Set default option for checkout page",
			"set_number_of_quantity": "Enter the number of quantity",
			"set_quantity": "Allow your customer to buy multiple quantities of this product or service.",
			"set_sepa_debit": "SEPA Direct Debit is a reusable, delayed notification payment method. This means that it can take up to 14 business days to receive notification on the success or failure of a payment after you initiate a debit from the customer’s account, though the average is five business days.",
			"set_terms_of_service": "Validate the terms of services into the invoice app.",
			"set_the_tax_percent_or_over_write_the_tax_percent": "Set the tax percent or over write the tax percent.",
			"shipping_address": "Shipping Address",
			"show": "Show",
			"show_additional_info": "Show Additional/Business Info",
			"show_address": "Show Address",
			"single": "Onetime",
			"subscription_starts_on": "Subscription Starts On",
			"subscriptions": "Subscription",
			"tax": "Tax",
			"terms_of_service": "Terms Of Service",
			"this_coupon_will_be_apply_on_upto": "This coupon will be applied on up to",
			"toggle_if_you_the_customer_to_submit_his_billing_information": "Toggle if you want the customer to submit his billing information",
			"toggle_if_you_the_customer_to_submit_his_shipping_information": "Toggle if you want the customer to submit his shipping information",
			"toggle_if_you_want_to_allow_One_Time": "Toggle if you want to allow onetime payment into the checkout page",
			"toggle_if_you_want_to_allow_recurring": "Toggle if you want to allow recurring payment into the checkout page",
			"toggle_if_you_want_to_show_additional_info_from_the_settings_into_checkout": "Toggle if you want to show additional/business info from the settings into the checkout page",
			"toggle_if_you_want_to_show_your_address_into_the_checkout": "Toggle if you want to show stripe support address into the checkout page",
			"toggle_if_you_want_to_verify_your_customer_zip_code_before_sell": "Toggle if you want to verify your customer zip code before sell",
			"trial_period": "Trial Period",
			"update_invoice": "Update Invoice",
			"updating_a_short_link": "Updating new short link...",
			"updating_a_subscription": "Updating a subscription...",
			"upload": "Upload",
			"user": "User",
			"view": "View",
			"view_coupon": "View Coupon",
			"weekly": "Weekly",
			"yearly": "Yearly",
			"zip_code_verification": "ZIP Code Verification",
			"end_after_DX_payments": "End After {{maxPayment}} Payments",
			"add_custom": "Add Custom"
		},
		"success": {
			"coupon_has_been_created": "Coupon had been created.",
			"new_invoice_has_been_saved": "New invoice has been saved.",
			"updated_invoice_has_been_saved": "Invoice has been updated."
		}
	},
	"invoice_metrics": "Invoice Metrics",
	"invoices": "Invoices",
	"just": "Just",
	"let_take_a_few_seconds_to_create_your_first_invoice": "let's take a few seconds to create your first invoice.",
	"login": {
		"label": {
			"account_login": "Account Login",
			"account_signin": "Account Signin",
			"create_new_account": "Create new account"
		}
	},
	"max_payments": "Max Payments",
	"modified": "modified",
	"monetary_value": "Monetary Value",
	"month_ago": "month ago",
	"monthly": "Monthly",
	"my_notifications": "My Notifications",
	"my_profile": "My Profile",
	"name": "Name",
	"name_is_required": "Your name is required",
	"new": "NEW",
	"new_invoice": "New Invoice",
	"next": "Next",
	"not_matched": "Password and Confirm Password did not match.",
	"notification": "Notification",
	"notifications": {
		"error": {
			"please_enter_message": "Please enter email message",
			"please_enter_subject": "Please enter email subject."
		},
		"label": {
			"Invoice Title": "Invoice Title",
			"bank_transfer": "Bank Transfer",
			"business_name": "Business Name",
			"card_brand": "Card Brand",
			"customer_name": "Customer Name",
			"customer_notification": "Customer Notifications",
			"email": "Email",
			"enter_email_message": "Enter email message",
			"enter_email_subject": "Enter email subject",
			"failed_payments": "Failed payments",
			"invoice_description": "Invoice Description",
			"last_4_card_number": "Last 4 card number",
			"message": "Message",
			"my_notification": "My Notifications",
			"payment": "Payment Email Notification",
			"payment_email_notification": "Payment Email Notification",
			"payment_receipt": "Payment Receipt",
			"refund": "Refund Email Notification",
			"refund_amount": "Refund Amount",
			"refund_date": "Refund Date",
			"refund_email_notification": "Refund Email Notification",
			"refund_notification": "Refund Notification",
			"refund_payments": "Refund Payments",
			"send_customer_upcoming_invoice_notification": "Send your customer an email when there is a subscription going to renew",
			"send_you_an_email_for_all_bank_transfers": "Send you a notification for all bank transfers",
			"send_you_an_email_for_all_failed_payments": "Send you a notification for all failed payments",
			"send_you_an_email_for_all_payment_refunds": "Send you a notification for all payment refunds",
			"send_you_an_email_for_all_successful_payments": "Send you a notification for all successful payments",
			"send_you_an_email_for_update_payment_method": "Send you a notification when customer update their payment method for a subscription",
			"send_your_customer_an_email_for_successful_refunds": "Send your customer an email for successful refunds",
			"send_your_customer_an_email_receipt_for_the_payment_he_makes": "Send your customer an email receipt for the payment he/she makes",
			"subject": "Subject",
			"successful_payments": "Successful Payments",
			"upcomingInvoice": "Upcoming Invoice Notification",
			"upcoming_invoice_notification": "Upcoming Invoice Notification",
			"update_payment_method": "Update Payment Method",
			"your_notification_is_updated": "Your notification is updated!",
			"send_customer_an_email_for_all_failed_payments": "Send your customer an email for failed payment"
		},
		"success": {}
	},
	"number": "Number",
	"ok": "Ok",
	"paid": "Paid",
	"password_is_required": "Password is required",
	"password_reset": "Password Reset",
	"password_reset_email_is_on_it_way": "Password reset email is on it's way!",
	"payment_origin": "Payment Origin",
	"payment_plans": {
		"error": {
			"enter_valid_max_payment": "Please enter the maximum number of payment value."
		},
		"label": {
			"payment": "Payment Plan"
		}
	},
	"payment_receipt": "Payment Receipt",
	"payments": {
		"error": {
			"decimal_place": " decimal place.",
			"refund_amount_must_be_between": " Refund amount must be between "
		},
		"label": {
			"add_refund": "Add refund",
			"additional_details": "Additional Details",
			"all": "All",
			"amount": "Amount",
			"amount_due": "Amount Due",
			"amount_paid": "Amount Paid",
			"checkout_date": "Checkout Date",
			"customer_information": "Customer information",
			"date_paid": "Date Paid",
			"description": "Description",
			"disputed": "Disputed",
			"download_receipt": "Download receipt",
			"due": "due",
			"duplicate": "Duplicate",
			"failed": "Failed",
			"fraud": "Fraud",
			"full_refund": "Full Refund",
			"give_full_refund_to_the_customer": "Give full refund to the customer",
			"issue_refund": "Issue Refund",
			"paid": "Paid",
			"paid_on": "Paid on",
			"partial_refund": "Partial Refund",
			"payment_date": "Payment Date",
			"payment_details": "Payment Details",
			"pending": "Pending",
			"please_wait": "Please wait!",
			"qty": "Qty",
			"receipt": "Receipt",
			"refunded": "Refunded",
			"requested_by_customer": "Requested by customer",
			"retry_payment": "Retry Payment",
			"retry_payment_confirm_description": "Are you sure want to retry failed payment?",
			"select_refund_amount_for_the_customer": "Select refund amount for the customer",
			"sub_total": "Sub Total",
			"unit_price": "Unit Price",
			"view_receipt": "View receipt",
			"void": "Void",
			"your_payments": "Your Payments",
			"payout": "Your payout of {{currencySymbol}} {{amount}} {{currency}} is scheduled for {{arrivalDate}}."
		},
		"success": {
			"refund_succeed": "Refund Succeed."
		}
	},
	"permission": {
		"error": {
			"please_enter_feature_name": "Please enter feature name."
		},
		"label": {
			"add_permission": "Add Permission",
			"custom_action": "Custom Action",
			"default_actions": "Default Actions are create, delete, update and read.",
			"default_permission": "Default Permission",
			"feature_name": "Feature Name",
			"permission": "Permission",
			"permission_list": "Permission List"
		},
		"success": {
			"permission_added_successfully": "Permission added successfully!"
		}
	},
	"phone": "Phone",
	"plan": "Plan",
	"plan_management": "Plan Management",
	"plan_management_page": {
		"label": {
			"discount": "Discount",
			"interval": "Interval",
			"name": "Name",
			"no_plan_found": "The plans not found.",
			"price": "Price",
			"select_plan": "Select plan",
			"transaction_fee_percentage": "Transaction Fee Percentage",
			"trial_period": "Trial Period"
		}
	},
	"please_enter_valid_name": "Please enter valid name.",
	"please_wait_while_your_payment_is_updated": "Please wait while your payment is updated...",
	"pricing": "Pricing",
	"print": "Print",
	"promo_code": "Promo code",
	"public_info": "Public Info",
	"receipts": {
		"label": {
			"additional_fields": "Additional Fields",
			"amount": "Amount",
			"amount_due": "Amount Due",
			"amount_paid": "Amount Paid",
			"bill_to": "Bill to",
			"checkout_date": "Checkout Date",
			"date_due": "Date due",
			"date_of_issue": "Date of issue",
			"date_paid": "Date Paid",
			"description": "Description",
			"due": "due",
			"invoice": "Invoice",
			"invoice_number": "Invoice number",
			"issued_refund_on": "Issued Refund on",
			"off": "OFF",
			"one_time_setup_fees": "One time setup fees",
			"paid_by": "Paid By",
			"paid_on": "Paid On",
			"payment_date": "Date paid",
			"payment_method": "Payment method",
			"qty": "Qty",
			"receipt": "Receipt",
			"receipt_number": "Receipt number",
			"ship_to": "Ship to",
			"sub_total": "Subtotal",
			"tax": "Tax",
			"terms_of_service": "Terms Of Service",
			"thank_you_for_your_business": "Thank you for your business!",
			"total": "Total",
			"unit_price": "Unit Price"
		}
	},
	"recurring_payment_for_subscrition": "Recurring Payment for Subscription",
	"refresh_billing_info": "Refresh BIlling Info",
	"refund_notification": "Refund Notification",
	"refunds": "Refunds",
	"remember_me": "Remember me",
	"remember_your_password": "Remember your password?",
	"reset_email": {
		"label": {
			"continue": "Continue",
			"header": "Reset email",
			"unable_update_email": "Unable to update your email address",
			"unable_update_expired_message": "Your update email link has been expired.",
			"update_email": "Updated email address",
			"update_email_message": "Your sign-in email address has been changed back to"
		}
	},
	"reset_password": "Reset Password",
	"reset_password_form": {
		"error": {
			"not_matched": "Password and Confirm Password did not match.",
			"please_enter_valid_confirm_password": "Please enter valid confirm password.",
			"please_enter_valid_new_password": "Please enter valid new password.",
			"please_select_business": "Please select a business."
		},
		"label": {
			"confirm_password": "Confirm password",
			"continue": "Continue",
			"message": "Your reset password link has been expired.",
			"new_password": "New password",
			"reset": "Submit",
			"reset_your_password": "Reset your password",
			"title": "Try resetting your password again"
		},
		"success": {
			"password_updated": "New password updated!"
		}
	},
	"select_business": {
		"label": {
			"continue": "Continue",
			"nav_button_title": "Change Business",
			"title": "Select the Account",
			"business_list_title": "Reset password for following accounts"
		}
	},
	"settings": {
		"error": {
			"account_number_and_confirm_account_number_not_match": "Account number and confirm account number does not match.",
			"enter_display_name_placeholder": "Enter display name ",
			"enter_something": "Please enter something.",
			"free_plan_not_exist": "Free Plan Not Found, Please Contact to Support Team",
			"new_password_and_retype_password_are_not_matched": "Entered new password and retype password are not matched.",
			"please_add_another_card_this_card_already_added": "Please add another card this card already added!",
			"please_enter_additional_field": "Please enter an additional field.",
			"please_enter_display_name": "Please enter display name.",
			"please_enter_facebook_pixel_id": "Please enter facebook pixel id.",
			"please_enter_new_name_or_email_address": "Please enter new name or email address.",
			"please_enter_new_password": "Please enter new password.",
			"please_enter_valid_account_number": "Please enter account number.",
			"please_enter_valid_confirm_account_number": "Please enter confirm account number.",
			"please_enter_valid_name": "Please enter valid name.",
			"please_enter_valid_percent": "Please enter valid percent",
			"please_enter_valid_routing_number": "Please enter routing number.",
			"please_enter_valid_tax_name": "Please enter valid tax name",
			"please_enter_valid_url": "Please enter a valid URL.",
			"please_sync_with_stripe": "Please Sync with stripe once to cancel your subscription.",
			"to_remove_this_payment_method_you_must_delete_all_the_subscriptions_mentioned_above": "To remove this payment method you must change default payment method applied to  all the subscriptions mentioned above.",
			"to_remove_this_tax_you_must_delete_all_the_invoice_mentioned_above": "To remove this tax you must remove tax applied from  all the invoices mentioned above."
		},
		"label": {
			"account_id": "Account Id",
			"account_settings": "Account Settings",
			"active": "Active",
			"add": "Add",
			"add_card_information": "Add Card Information",
			"add_new_bank_account": "Add New Bank Account",
			"add_new_card": "Add New Card",
			"add_new_tax": "Add New Tax Rate",
			"add_tax": "Add Tax",
			"additional_info": "Additional/Business Info",
			"address": "Address",
			"archived": "Archived",
			"billing": "Billing",
			"billing_address": "Billing Address",
			"business_image": "Business Image",
			"buy": "Buy",
			"cancel_account": "Cancel Account",
			"cancel_subscription": "Cancel Subscription",
			"card_expired": "Card Expired",
			"change_password": "Change Password",
			"checkout_domain": "Checkout domain",
			"checkout_language": "Checkout language",
			"choose_file": "Choose File",
			"city": "City",
			"confirm_new_mail": "Are you sure you want to update email to {{ newEmail }} ?",
			"confirm_new_mail_send": "A confirmation link has been sent to {{newEmail}}, please verify.",
			"country": "Country",
			"crop_image": "Crop Image",
			"custom_domain": "Custom Domain",
			"default": "Default",
			"default_currency": "Default Currency",
			"deleting_account": "Deleting account...",
			"deleting_card": "Deleting Card...",
			"deleting_payment_method": "Deleting payment method...",
			"description": "description",
			"difficult_to_setup": "Difficult to setup",
			"disconnect": "Disconnect",
			"display_checkOut_placeholder": "Enter CheckOut Domain",
			"display_name": "Display Name",
			"display_name_placeholder": "Enter display name",
			"display_plateformDomain_placeholder": "Enter Platform Domain",
			"display_subDomain_placeholder": "Enter Sub Domain",
			"do_you_have_anything_to_add": "Do you have anything to add",
			"edit_card": "Edit Card",
			"enter_one_time_setup_fees": "Please enter one time setup fees label.",
			"enter_your_email": "Enter your email",
			"enter_your_name": "Enter your name",
			"error": "Error",
			"exclusive": "Exclusive",
			"facebook_pixel_id": "Facebook Pixel Id",
			"facebook_pixel_id_placehoder": "Enter Your Facebook Pixel Id",
			"generate": "Generate",
			"icon": "Icon/Logo",
			"inclusive": "Inclusive",
			"isPending_email_message": "Please verify your new email, your request to change email to {{updatedEmail}} is pending.",
			"jurisdiction": "Jurisdiction",
			"login_details": "Login Details",
			"message_checkout_for_subDomain": "Sub domain for checkout app",
			"message_platform_for_subDomain": "Sub domain for platform app",
			"missing_feature_we_need": "Missing feature we need",
			"new_password": "New Password",
			"new_password_placeholder": "Enter your new password",
			"no_contracts_cancel_anytime": "No contracts, Cancel anytime",
			"not_using_it_enough_or_at_all": "Not using it enough  or at all",
			"old_password": "Old Password",
			"old_password_placeholder": "Enter your old password",
			"one_time_setup_fees_label": "One time setup fees label",
			"other": "Other",
			"payment_info": "Payment Info",
			"pending": "Pending",
			"plan": "Plan",
			"platform_domain": "Plateform domain",
			"platform_subDomain": "Platform URL ",
			"please_wait": "Please wait...",
			"please_wait_your_image_is_being_deleted": "Please wait your image is being deleted...",
			"please_wait_your_image_is_being_uploaded": "Please wait your image is being uploaded...",
			"preview": "Preview",
			"primary_redirect_url": "Primary Redirect URL",
			"refresh": "Refresh",
			"remove": "Remove",
			"retype_password": "Retype Password",
			"retype_password_placeholder": "Re enter your new password",
			"set_as_default": "Set As Default",
			"sorry_to_see_you_go": "Sorry to see you go. Do you mind giving us feedback so we can improve",
			"state": "State",
			"statement_descriptor": "Statement Descriptor",
			"stripe_has_been_disconnected": "Stripe has been disconnected...",
			"sub_domain": "Sub domain",
			"subscription_plan": "Subscription Plan",
			"support_email": "Support Email",
			"support_phone": "Support Phone",
			"switching_to_different_product": "Switching to different product",
			"sync_with_stripe": "Sync With Stripe",
			"syncing_with_stripe": "Syncing with Stripe. Please wait just a moment...",
			"tax_details": "Tax Details",
			"tax_rate": "Tax Rate",
			"tax_settings": "Tax Settings",
			"term_of_service": "Terms Of Services",
			"term_of_service_placeholder": "Terms of service.",
			"terminate": "Terminate",
			"there_are_some_invoice_which_having_this_tax": "There are some invoice which having this tax.",
			"there_are_some_subscription_which_having_this_payment_method": "There are some subscription which is having this payment method.",
			"toggle_if_you_want_to_show_terms_of_services_into_the_receipt": "Toggle if you want to show terms of services into the receipt.",
			"too_expensive": "Too expensive",
			"unarchive": "Unarchive",
			"unlimited_invoices": "Unlimited Invoices",
			"unlimited_monthly_volume": "Unlimited monthly volume",
			"update_tax_label": "Update Tax Label",
			"update_tax_name": "Update tax name",
			"update_tax_rate": "Update Tax Rate",
			"update_your_default_bank_account": "Updating Your Default Bank Account...",
			"update_your_default_card": "Updating Your Default Card...",
			"update_your_default_payment_method": "Updating your default payment method...",
			"your_new_bank_account_is_getting_added": "Your new bank account is getting added!",
			"your_new_card_is_getting_added": "Your new card is getting added!",
			"your_new_payment_method_is_getting_added": "Your new payment method is getting added!",
			"zapier": "Zapier",
			"zapier_api_key": "API key",
			"zapier_id": "Zapier Id",
			"zip": "Zip"
		},
		"success": {
			"login_with_new_email": "Please login with the new email.",
			"login_with_reset_email": "Please log in using this email.",
			"please_add_another_card_this_card_already_added": "Please add another card this card already added!",
			"your_account_details_are_successfully_updated": "Your account details are successfully updated!",
			"your_bank_account_has_been_deleted": "Your bank account has been deleted.",
			"your_card_has_been_deleted": "Your card has been deleted.",
			"your_default_bank_account_has_been_updated": "Your default bank account has been updated!",
			"your_default_card_has_been_updated": "Your default card has been updated!",
			"your_default_payment_method_has_been_updated": "Your default payment method has been updated!",
			"your_image_has_been_deleted": "Your image has been successfully deleted!",
			"your_invoice_details_successfully_updated": "Your invoice details successfully updated!",
			"your_new_account_has_been_added": "Your new bank account has been added!",
			"your_new_card_has_been_added": "Your new card has been added!",
			"your_new_payment_method_has_been_added": "Your new payment method has been added!",
			"your_password_is_successfully_updated": "Your password is successfully updated!",
			"your_payment_method_has_been_deleted": "Your payment method has been deleted.",
			"your_subscription_is_cancel_now": "Your subscription is cancel now!",
			"your_tax_details_is_updated": "Your tax details is updated."
		}
	},
	"setup_fees": "Setup Fees",
	"setup_intent_authentication_failure": "Could not authenticate your card please try again later!",
	"share_this_payment_link": "Share This Payment Link",
	"sign_out": "Sign Out",
	"signup": {
		"error": {
			"no_such_plan_available": "No such plan available.",
			"please_refresh_the_page_try_again": "Please refresh the page, Try again!"
		},
		"label": {
			"can't_find_link_check_spam": "Can't find your link? Check your spam folder.",
			"click_here": "click here",
			"continue": "Continue",
			"email_verification_link_has_been_sent": "Email verification link has been sent to",
			"enter_your_payment_details_to_activate_your_account": "Enter your payment details to activate your account.",
			"enter_your_payment_details_to_re_activate_your_account": "Enter your payment details to re-activate your account.",
			"joining_message": "Thank you for joining!",
			"open_gmail": "Open Gmail",
			"open_outlook": "Open Outlook",
			"please": "Please",
			"please_enter_your_billing_information": "Please enter your billing information.",
			"please_update_your_billing_information": "Please update your billing information.",
			"second(s)": "seconds(s)",
			"to_resend_email_verification_details": "to resend email verification details.",
			"you_can_retry": "you can retry in the "
		}
	},
	"slack": {
		"error": {
			"error_on_disconnect": "Problem Disconnecting with slack.",
			"problem_connecting_with_slack": "Problem connecting with slack"
		},
		"label": {
			"add_to_slack": "Add to Slack",
			"connected_with": "Connected with",
			"disconnect_slack_statement": "Are you sure you want to disconnect with slack workspace?",
			"please_connect_to_workspace": "Please connect with your slack workspace to receive notifications.",
			"slack": "Slack",
			"workspace": "workspace"
		},
		"success": {
			"connected_successfully": "Slack connected successfully.",
			"disconnected_successfully": "Disconnect with workspace successfully."
		}
	},
	"start_your": "Start Your",
	"start_your_14_day_free_trial": "Start Your 14 Days Free Trial!",
	"started": "Started",
	"status": "Status",
	"sub_total": "Sub Total",
	"subscriptionPlans": {
		"label": {
			"basic_plan": {
				"charge_per_transaction": " Charge per transaction",
				"no_contracts_cancel_anytime": "No contracts, Cancel anytime",
				"no_monthly_charges": "No monthly charges",
				"unlimited_one_time_invoices": "Unlimited one-time invoices"
			},
			"plan_changed_successfully": "Your Subscription Plan Has Been Changed Successfully.",
			"pro": {
				"no_contracts_cancel_anytime": "No contracts, Cancel anytime",
				"unlimited_invoices": "Unlimited Invoices",
				"unlimited_monthly_volume": "Unlimited monthly volume"
			}
		},
		"title": {
			"Mo": "Mo",
			"current_plan": "Current Plan",
			"downgrade": "Downgrade",
			"upgrade": "Upgrade"
		}
	},
	"subscription_details": "Subscription Details",
	"subscription_plan": "Subscription Plan",
	"subscriptions": {
		"error": {
			"please_enter_billing_address": "Please enter valid billing address.",
			"please_enter_cancel_date_between": "Please select date between subscription start date to subscription end date.",
			"please_enter_city": "Please enter valid city.",
			"please_enter_date_to_resume": "Please enter date to resume.",
			"please_enter_day_after_today": "Please enter day after Today.",
			"please_enter_day_before_cancel_at": "Please enter resume date before your subscription end.",
			"please_enter_expired_date": "Please enter valid expired date.",
			"please_enter_name": "Please enter valid name.",
			"please_enter_state": "Please enter valid state.",
			"please_enter_valid_cancel_date": "Please select valid cancel date.",
			"please_enter_valid_date": "Please enter a valid date."
		},
		"label": {
			"active": "ACTIVE",
			"active_subscriptions": "Active",
			"amount": "Amount",
			"are_you_sure_you_want_to_resume_your_subscription": "Are you sure you want to resume this Subscription?",
			"billing_starts_on": "Billing Starts On",
			"cancel_on_custom_date": "Subscription will be canceled on the selected date.This option might lead you to charge proration for unused time.",
			"cancel_subscription": "Cancel Subscription",
			"canceled": "CANCELED",
			"canceled_subscriptions": "Canceled ",
			"checkout_date": "Checkout Date",
			"create_date": "Create Date",
			"credit_cards": "Credit Cards",
			"customer_details": "Customer Details",
			"day_left_for_your_subscription_to_resume": "Day(s) left for your subscription to resume",
			"end_of_the_term": "End of the term",
			"end_the_subscription_at_the_end_of_the_current_period": "End the subscription at the end of the current period.",
			"end_the_subscription_immediately": "End the subscription immediately.",
			"ends_on": "Ends on",
			"free_service": "Offering Service For Free",
			"from_upcoming_invoice": "From Upcoming Invoice",
			"immediately": "Immediately",
			"incomplete": "INCOMPLETE",
			"incomplete_expired": "INCOMPLETE EXPIRED",
			"invoice_status": "Invoice Status",
			"max_payments": "Max Payments",
			"no_date_specified": "You Need to Resume Subscription Manually.",
			"not_offering_service": "Not Offering Service",
			"offering_free_service": "Pausing subscription because Offering Service for free.",
			"on_a_custom_date": "On a custom date",
			"past_due": "PAST DUE",
			"pause": "Pause",
			"pause_subscription": "Pause Subscription",
			"pause_this_subscription_till_given_date": "Pause this subscription till given date if not date specified switch on the toggle.",
			"paused": "PAUSED",
			"recurring": "Recurring",
			"resume": "Resume",
			"resume_automatically": "Resume Automatically",
			"resume_from_next_cycle": "Resume Payment for this subscription from the next cycle.",
			"resume_immediately": "Resume Payment for this subscription immediately",
			"resume_manually": "Resume Manually",
			"resume_on": "Resumes On",
			"resume_subscription": "Resume Subscription",
			"select": "Select",
			"select_date": "Select Date",
			"select_date_for_resume": "Subscription will resume automatically on selected date",
			"select_outstanding_invoice": "Please select the outstanding invoice(s) you want to take charge upon resuming.",
			"share_this_payment_link_to_complete_3ds": "Share this payment link to complete 3ds and get payment",
			"start_date": "Start Date",
			"subscription": "Subscription",
			"subscription_cancel": "Subscription Cancel",
			"subscription_canceled_successfully": "Subscription Canceled successfully.",
			"subscription_details": "Subscription Details",
			"subscription_metrics": "Subscription Metrics",
			"subscription_name": "Subscription Name",
			"subscription_paused_successfully": "Subscription Paused successfully.",
			"subscription_resume_successfully": "Subscription Resumed successfully.",
			"subscription_starts_date": "Subscription Start Date",
			"successful_payments": "Successful Payments",
			"temporary_free_collect_later": "Temporarily  offer service for free and collect all outstanding invoices on resume.",
			"temporary_pause": "Temporary Pause subscription",
			"total_amount": "Total Amount",
			"trialing": "TRIALING",
			"unable_to_provide_service": "Pausing subscription because Unable to provide service.",
			"unpaid": "UNPAID",
			"unsubscribe": "Unsubscribe",
			"your_subscription": "Your Subscription",
			"update_billing_cycle_date": "Update billing cycle date",
			"update_billing_cycle": "Update Billing Cycle",
			"subscription_will_charge_everyday": "Your subscription will charge every day.",
			"subscription_will_charge_everyday_trialing": "Your subscription will be charged daily from the {{billingDate}}",
			"subscription_will_charge_max_payments_day": "Your subscription will charge for {{maxPayment}} day(s).",
			"subscription_will_charge_max_payments_day_trialing": "Your subscription will be charged on {{billingDate}} for {{maxPayment}} daily payments.",
			"subscription_charge_month": "Current billing cycle date is {{billingDate}} of every month.",
			"subscription_will_charge_max_payments_month": "Current billing cycle date is {{billingDate}} for {{maxPayment}} month(s).",
			"subscription_charge_year": "Current billing cycle date is {{billingDate}} of every year.",
			"subscription_will_charge_max_payments_year": "Current billing cycle date is {{billingDate}} for {{maxPayment}} year(s).",
			"new_billing_cycle": "New billing cycle",
			"update_custom_payment_phase_billing_cycle": "Update custom payment phase billing cycle",
			"next_bill_on": "Next Bill on {{nextBillingDate}}",
			"subscription_will_charge_weekly": "Bill on {{billingDate}}. Weekly billing.",
			"subscription_will_charge_bi_weekly": "Bill on {{billingDate}}. Bi-Weekly billing.",
			"custom_plan_phase_billing_cycle": "Your select custom plan phase billing cycle is {{billingDate}}",
			"subscription_will_charge_max_payments_week": "Current billing cycle date is {{billingDate}} for {{maxPayment}} week(s).",
			"subscription_will_charge_max_payments_bi_week": "Current billing cycle date is {{billingDate}} for {{maxPayment}} biweekly"
		},
		"success": {}
	},
	"successful_payments": "Successful Payments",
	"support": "Support",
	"sync_with_stripe": "Sync With Stripe",
	"thank_you": {
		"error": {},
		"label": {
			"redirect_link": "Click here to redirect the invoice page.",
			"thank_you_message": "Thank you, Your payment method has been updated."
		},
		"success": {}
	},
	"thank_you_for_your_business": "Thank you for your business!",
	"title": "Title",
	"todos": {
		"error": {
			"add_max_tags": "You can add maximum 5 tags only.",
			"please_enter_title": "Please enter title for your Todo",
			"please_refresh_the_page_try_again": "Please refresh the page, Try again!",
			"required_customer": "Please select customer"
		},
		"label": {
			"actions": "Actions",
			"add_new_todo": "Add New Todo",
			"archive": "Archive",
			"archived": "Archived",
			"are_you_sure_you_want_to_archive_this_todo": "Are you sure you want to archive this Todo?",
			"are_you_sure_you_want_to_remove_this_todo": "Are you sure you want to remove this Todo?",
			"assign_to_single_message": "You can assign todo to only one customer.",
			"assigned": "Assigned",
			"assigned_by": "Assigned By",
			"assigned_to": "Assigned To",
			"completed": "Completed",
			"create": "Create",
			"create_new_todos": "Create New Todos",
			"customer_added": "Customer Added!",
			"customer_name_notes": "{{ customerName }}'s Notes",
			"customer_todos": "Customer Todos",
			"due_at": "Due at",
			"due_date": "Due Date",
			"due_date_tooltip": "Due date selection starts from tomorrow.",
			"modified": "Modified",
			"my_todos": "My Todos",
			"no_customer_found": "No Customer Found...",
			"notes": "Notes",
			"open": "Open",
			"pending": "Pending",
			"please_select_customer": "Please search and select customer from above dropdown.",
			"search_select_customer": "Search and Select Customer",
			"select_due_date": "Select Due Date",
			"selected_customer_is": "Selected customer is:-  ",
			"send_invite": "Send Invite",
			"tags": "Tags",
			"title": "Title",
			"todo_details": "Todo Details",
			"your_todos": "Your Todos"
		},
		"success": {
			"customer_removed_successfully": "Customer Removed Sucessfully!",
			"marked_completed": "Marked as completed.",
			"marked_pending": "Marked as Pending.",
			"your_notes_has_been_save_successfully": "Your Notes has been saved successfully.",
			"your_todo_has_been_archived_successfully": "Your Todo has been archived successfully.",
			"your_todo_has_been_created_successfully": "Your Todo has been created successfully.",
			"your_todo_has_been_removed_successfully": "Your Todo has been removed successfully.",
			"your_todo_has_been_updated_successfully": "Your Todo has been updated successfully."
		}
	},
	"total_pageviews": "Total Pageviews",
	"total_paid": "Total Paid",
	"total_revenue": "Total Revenue",
	"translation": {
		"label": {
			"de-DE": "German",
			"en-US": "English",
			"es-ES": "Spanish",
			"fr-FR": "French",
			"pt-PT": "Portuguese"
		}
	},
	"try_it_now": "Try it now",
	"type": "Type",
	"unauthorized": "There is no user record corresponding to this identifier. The user may have been deleted.",
	"unique_visitors": "Unique Visitors",
	"update_email": {
		"label": {
			"expired_title": "The link for updating your email has expired. Please try again.",
			"invalid_id": "Invalid Link!",
			"login": "Login",
			"update_your_email": "Email Verified",
			"update_your_email_expired": "Link Expired!",
			"updated_title": "Your email is now updated to {{newEmail}}."
		}
	},
	"update_payment_method_page": {
		"error": {},
		"label": {
			"please_wait": "Please wait...",
			"update_payment_method": "Update Payment Method",
			"your_new_payment_method_has_been_added": "Your new payment method has been added!",
			"your_new_payment_method_is_getting_added": "Your new payment method is getting added!"
		},
		"success": {}
	},
	"user": {
		"label": {
			"delete_user": "Delete User",
			"false": "false",
			"no_card": "No card",
			"share_signup_link": "Share this signup link",
			"share_update_payment_method_link": "Share this update payment method link",
			"true": "true",
			"update_plane": "Update Plan"
		},
		"success": {
			"Successfully_done": "Successfully done!"
		}
	},
	"users": "Users",
	"verify_email": {
		"label": {
			"continue": "Continue",
			"expired_message": "Your email verifying link has been expired.",
			"expired_title": "Try verifying your email again",
			"verified_message": "You can now continue your account process.",
			"verified_title": "Your email has been verified",
			"verify_your_email": "Verify your email"
		}
	},
	"view_invoice": "View Invoice",
	"view_receipt": "View Receipt",
	"weekly": "Weekly",
	"welcome": "Welcome",
	"with_a_free": "with a FREE",
	"yearly": "Yearly",
	"your_invoices": "Your Invoices",
	"your_name": "Your Name",
	"payment_status": {
		"succeeded": "Success! Payment received.",
		"processing": "Payment processing. We'll update you when payment is received.",
		"requires_payment_method": "Payment failed. Please try another payment method.",
		"something_went_wrong": "Something went wrong, Please contact support!"
	},
	"checkout": {
		"label": {
			"btn_pay": "Pay",
			"btn_next": "Next",
			"billing": "Billing",
			"shipping": "Shipping",
			"payment_details": "Payment details",
			"billing_shipping": "Billing/Shipping Details",
			"billing_details": "Billing Details",
			"shipping_details": "Shipping Details",
			"select_payment_method": "Select payment method",
			"add_new_payment_method": "Add new payment method",
			"same_billing_shipping": "Shipping same as billing",
			"btn_done": "Done",
			"btn_payment": "Payment Page"
		},
		"error": {
			"nothing_to_invoice_for_customer": "Please use a different email address as this email was used previously to pay in a different currency.",
			"something_went_wrong": "Something went wrong,Please contact support!",
			"plan_updated": "Your plan has updated."
		}
	},
	"errors": {
		"OH_SNAP": "Oh snap!",
		"WRONG_CREDENTIAL": "You have entered incorrect credentials.",
		"USER_NOT_FOUND": "User not Found",
		"PASSWORD_DOES_NOT_MATCH": "The password entered by you doesn't match.",
		"CURRENT_PASSWORD_WRONG": "Current password is wrong.",
		"SOMETHING_WENT_WRONG_MAKE_SURE_YOUR_URL_CORRECT": "Something went wrong, Please make sure your url is correct",
		"SOMETHING_WENT_WRONG": "Something went wrong",
		"HOST_NAME_ALREADY_EXISTS": "Host name already exists",
		"CANNOT_UPDATE_SAME_URLSETTINGS": "Cannot update same url setting",
		"UNIQUE_NAME_SHOULD_BE_UNIQUE": "Unique Name should be unique",
		"PARSER_CATEGORY_NAME_SHOULD_BE_UNIQUE": "Parser category name already exists",
		"UNIQUE_NAME_ALREADY_EXISTS": "Unique name already exists.",
		"PLEASE_LOGIN_WITH_GOOGLE": "Please login with google",
		"USER_WITH_THIS_EMAIL_ALREADY_EXIST": "An account already exists with this email.",
		"PASSWORD_CHANGE_SUCCESSFULLY": "Your password has been updated successfully.",
		"EMAIL_NOT_EXIST": "Email Not Exist",
		"EMAIL_NOT_EXIST_FOR_REQUEST_PASSWORD": "Thanks, check your email for instructions to reset your password.",
		"CODE_NOT_MATCHED": "The verification link has been expired.",
		"TIME_OUT": "The verification link has been expired.",
		"PLEASE_TRY_AGAIN": "Please try again",
		"CODE_EXPIRE": "The verification link has been expired.",
		"USER_ALREADY_VERIFIED": "User already verified with this email.",
		"UNABLE_TO_VERIFY": "Unable to verify email!",
		"UNABLE_TO_UPDATE_VERIFICATION_CODE": "Unable to update verification code.",
		"UNABLE_TO_CREATE_VERIFICATION_CODE": "Unable to create verification code.",
		"UNABLE_TO_UPDATE_USER": "Unable to update user.",
		"EMAIL_IS_REQUIRED": "Email required.",
		"STRIPE_PLAN_ID_NOT_FOUND": "Plan update fail, please try again",
		"CAPTCHA_VERIFICATION_FAILED": "Captcha verification failed",
		"THIS_PLAN_IS_NOT_DEFINED": "This plan is not defined",
		"REQUEST_FAILED": "The request has been failed. You will be not charged for this request.",
		"BAD_REQUEST": "The request has been failed. You will be not charged for this request.",
		"NO_IMAGES_FOUND": "No image found, you will be not charged for this request.",
		"PAYMENT_UNSUCCESSFUL": "Payment Unsuccessful.",
		"EMAIL_REQUIRED": "Email required.",
		"JS_RENDERING_IS_NOT_ENABLE_FOR_THIS_PLAN": "Your plan does not have the JS Rendering feature. Kindly upgrade your plan to avail this feature.",
		"RESET_PASSWORD_LINK_EXPIRE": "The verification link has been expired.",
		"US_GEO_TARGETING_IS_NOT_ENABLE_FOR_THIS_PLAN": "US GEO targeting is not enable for this plan",
		"EU_GEO_TARGETING_IS_NOT_ENABLE_FOR_THIS_PLAN": "EU GEO targeting is not enable for this plan",
		"CONCURRENT_COUNT_EXCEED": "Concurrent count exceeded",
		"CREDIT_LIMIT_EXCEED": "Your credit limit has been exceeded.",
		"ALL_GEO_TARGETING_IS_NOT_ENABLE_FOR_THIS_PLAN": "Your plan does not have the All GEO Targeting feature. Kindly upgrade your plan to avail this feature.",
		"PLAN_NOT_FOUND": "There is no active plan on your account. Kindly purchase/upgrade your plan.",
		"UNABLE_TO_CREATE_FREE_PLAN": "Unable to create a free plan, Please try again",
		"CONTACT_SUPPORT": "Please contact support on",
		"INVALID_NAME": "Invalid plan name",
		"INVALID_STRIPE_PLAN_ID": "Stripe plan id is invalid",
		"INVALID_PERIOD": "Plan period is invalid",
		"TEAM_NOT_FOUND": "Team Not Found",
		"USER_ALREADY_IN_TEAM": "User already in team.",
		"TEAM_MEMBER_LIMIT_EXCEED": "Team member limit exceed in current plan.",
		"INVALID_MAX_USERS": "Please enter valid max users number.",
		"INVALID_US_GEO_TARGETING": "Invalid US Geo Targeting",
		"INVALID_EU_GEO_TARGETING": "Invalid EU Geo Targeting",
		"PREMIUM_PROXY_IS_NOT_ENABLE_FOR_THIS_PLAN": "Premium proxy is not enable for this plan.",
		"TEAM_NOT_EXIST": "Team does not exist",
		"undefined": "Something went wrong",
		"Unauthorized": "Please try again",
		"Forbidden": "Please try again",
		"USER_REMOVED_FROM_TEAM": "This user cannot be invited. Please contact support.",
		"INVITATION_EMAIL_MISMATCH": "Please use invited email.",
		"INVITATION_CODE_NOT_FOUND": "The verification link has been expired.",
		"USER_INVITED_BUT_DECLINED": "This user has been invited and it has declined the invitation.",
		"USER_TEAM_NOT_EXISTS": "Your team does not exists, Kindly contact our support team.",
		"USER_CANNOT_INVITE_HIMSELF": "You can not invite yourself.",
		"INVALID_ID": "Data not found",
		"INVALID_EMAIL": "Email is invalid",
		"URL_GET_FAILED": "Records get failed with applied filter",
		"NO_URLS_SELECTED": "Please select row",
		"SESSION_EXPIRE": "Session Expired",
		"PLAN_ALREADY_MAPPED": "Plan already mapped",
		"FAILED_TO_RETRIEVE_COUPON": "Invalid Coupon",
		"INVITATION_EXPIRED": "The invitation link has been expired.",
		"TEAM_HAS_NO_ADMIN": "Current team has no Admin user.",
		"PLAN_GROUP_ALREADY_DELETED": "Plan group with same name is already deleted",
		"PLAN_NAME_ALREADY_EXISTS": "Plan name already exists",
		"PLAN_GROUP_NAME_ALREADY_EXISTS": "Plan group with same name is already exist or deleted",
		"PLAN_ALREADY_EXIST_IN_PLAN_GROUP": "This plan(s) already exist in plan-group",
		"PROXY_GROUP_NAME": "Proxy group name is Unique",
		"INVALID_PROXY_GROUP_NAME": "Proxy group name is empty.",
		"INVALID_PROXY_Id": "Proxy url is empty.",
		"PROXY_ALREADY_EXIST": "Proxy already exits.",
		"INVALID_PARSER_ID": "Invalid Parser Id",
		"PARSER_SUBSCRIPTION_ALREADY_EXIST": "Parser Subscription Already Exists",
		"PARSER_NOT_FOUND": "Parser Not Found",
		"PENDING_FOR_ACTIVATION": "This parser is pending for activation. You cannot subscribe to it at this moment.",
		"PARSER_FILE_NOT_FOUND": "This parser having unexpected error.",
		"PARSER_INACTIVE": "This parser is not active at this moment.",
		"PARSER_SUBSCRIPTION_NOT_FOUND": "Parser subscription not found.",
		"INVALID_PARSER_SUBSCRIPTION_REQUEST_ID": "Invalid Parser subscription request id",
		"USER_DATA_UPDATE_FAILED": "User Data Update Failed.",
		"INVALID_FIRST_NAME": "Invalid first name.",
		"INVALID_LAST_NAME": "Invalid last name.",
		"INVALID_MIDDLE_NAME": "Invalid middle name.",
		"INVALID_IS_ACTIVE": "Invalid active input.",
		"INVALID_IS_DELETED": "Invalid deleted input.",
		"USER_IS_NOT_ACTIVE": "Your account has been deactivated by the admin. Please contact customer support for more information.",
		"INVALID_WEBHOOK_ID": "Invalid webhook id",
		"WEBHOOK_DELETE_FAILED": "Webhook delete failed",
		"WEBHOOK_ALREADY_DELETED": "This webhook already deleted previously.",
		"WEBHOOK_RESPONSE_FAILED": "Webhook response failed",
		"WEBHOOK_UPDATE_FAILED": "Webhook updated failed",
		"WEBHOOK_NOT_FOUND": "Webhook not found",
		"INVALID_STATUS": "Invalid status",
		"INVALID_URL": "Invalid url",
		"INVALID_DESCRIPTION": "Invalid description",
		"INVALID_WEBHOOK_NAME": "Invalid webhook name",
		"THIS_PROXY_IS_NOT_UP": "This proxy is not up.",
		"PARSER_CATEGORY_DELETE_FAILED": "Failed to delete parser category.",
		"PROXY_PROVIDER_IS_ALREADY_DELETED": "This proxy provider is already deleted.",
		"PROXY_NOT_FOUND": "Proxy not found.",
		"INVALID_PARSER_CATEGORY_NAME": "Invalid parser category name",
		"INVALID_UNIQUE_NAME": "Invalid parser category unique name",
		"PARSER_CATEGORY_UPDATE_FAILED": "Parser category update failed",
		"NO_PLANS_FOUND": "No plans found",
		"INVALID_PLAN": "This plan is not valid",
		"NO_INTEGRATION_FOUND": "No integration found",
		"INVALID_IS_ARCHIVE": "Invalid archive",
		"INVALID_PAGE_NO": "Invalid page number",
		"INVALID_PAGE_SIZE": "Invalid page size",
		"PROXY_UPDATE_FAILED": "Proxy update failed",
		"INVALID_PARSER_NAME": "Invalid parser name",
		"INVALID_RESPONSE_TYPE": "Invalid response type",
		"INVALID_TYPE": "Invalid type",
		"INVALID_FILENAME": "Invalid file name",
		"INVALID_KEYWORDS": "Invalid keywords",
		"INVALID_ADVANCE_DESCRIPTION": "Invalid advance description",
		"FAILED_TO_CHANGE_PARSER_TYPE_FROM_PRIVATE_TO_PUBLIC": "Parser cannot be changed to public",
		"FILE_UPLOAD_FAILED": "This file is empty",
		"FAILED_TO_CREATE_PARSER_SUBSCRIPTION": "Failed to create parser subscription",
		"URL_NOT_FOUND": "Url not found",
		"INVALID_TEAM_ID": "Invalid team id",
		"INVALID_CREDIT": "Invalid credit",
		"INVALID_USER_ID": "Invalid user id",
		"INVALID_PLAN_GROUP_ID": "Invalid plan group id",
		"NO_USERS_FOUND": "No users found",
		"INVALID_DYNAMIC_INPUTS": "Invalid dynamic input",
		"FCM_TOKEN_NOT_FOUND_FOR_THIS_USER": "Fcm Token not found for this user.",
		"SERVER_UNDER_MAINTENANCE": "Server under maintenance",
		"REQUEST_BLOCKED_CONTACT_SUPPORT": "This request has been blocked. Please contact support for more information. support@stubified.com",
		"NOT_FOUND": "Invalid Id",
		"AUTOMATION_LIMIT_EXCEEDED": "Automation limit exceeded",
		"skipAutomation": "Automation Under Maintenance",
		"CAPTCHA_IDENTIFY": "Failed to process due to captcha",
		"INVALID_MAX_RETRY": "Invalid max retry number",
		"INVALID_OCCURRENCE": "Invalid Occurrence number",
		"TEAM_ALREADY_HAVE_AN_ACTIVE_PLAN": "This team already have an active plan",
		"USER_DATA_NOT_FOUND": "User data not found",
		"ERROR_TO_REACH_OUT": "Error! Reach out to support@stubified.com",
		"NO_PLAN_FOUND": "No plan found",
		"PLAN_UNAVAILABLE_USE_DEFAULT": "The plan you want is unavailable, so let's go with our default",
		"URL_SESSION_NOT_FOUND": "Instagram not connected",
		"AUTOMATION_TIME_OUT": "The verification link has been expired.",
		"INSTAGRAM_SESSION_EXPIRED": "Instagram Session Expired.",
		"THIS_COUNTRY_IS_NOT_ENABLE_FOR_THIS_PLAN": "Selected Geotargeting is not enable for this plan",
		"PARSER_RESPONSE_TYPE_NOT_EXIST": "The response type specified is not supported by this parser.",
		"stripe": {
			"AUTHENTICATION_REQUIRED": "The card was declined as the transaction requires authentication.",
			"APPROVE_WITH_ID": "The payment cannot be authorized.",
			"CALL_ISSUER": "The card has been declined for an unknown reason.",
			"CARD_NOT_SUPPORTED": "The card does not support this type of purchase.",
			"CARD_VELOCITY_EXCEEDED": "The customer has exceeded the balance or credit limit available on their card.",
			"CURRENCY_NOT_SUPPORTED": "The card does not support the specified currency.",
			"DO_NOT_HONOR": "The card has been declined for an unknown reason.",
			"DO_NOT_TRY_AGAIN": "The card has been declined for an unknown reason.",
			"DUPLICATE_TRANSACTION": "A transaction with identical amount and credit card information was submitted very recently.",
			"EXPIRED_CARD": "The card has expired.",
			"FRAUDULENT": "The payment has been declined as Stripe suspects it is fraudulent.",
			"GENERIC_DECLINE": "The card has been declined for an unknown reason.",
			"INCORRECT_NUMBER": "The card number is incorrect.",
			"INCORRECT_CVC": "The CVC number is incorrect.",
			"INCORRECT_PIN": "The PIN entered is incorrect. This decline code only applies to payments made with a card reader.",
			"INCORRECT_ZIP": "The ZIP/postal code is incorrect.",
			"INSUFFICIENT_FUNDS": "The card has insufficient funds to complete the purchase.",
			"INVALID_ACCOUNT": "The card, or account the card is connected to, is invalid.",
			"INVALID_AMOUNT": "The payment amount is invalid, or exceeds the amount that is allowed.",
			"INVALID_CVC": "The CVC number is incorrect.",
			"INVALID_EXPIRY_MONTH": "The expiration month is invalid.",
			"INVALID_EXPIRY_YEAR": "The expiration year is invalid.",
			"INVALID_NUMBER": "The card number is incorrect.",
			"INVALID_PIN": "The PIN entered is incorrect.",
			"ISSUER_NOT_AVAILABLE": "The card issuer could not be reached, so the payment could not be authorized.",
			"LOST_CARD": "The payment has been declined because the card is reported lost.",
			"MERCHANT_BLACKLIST": "The payment has been declined because it matches a value on the Stripe user’s block list.",
			"NEW_ACCOUNT_INFORMATION_AVAILABLE": "The card, or account the card is connected to, is invalid.",
			"NO_ACTION_TAKEN": "The card has been declined for an unknown reason.",
			"NOT_PERMITTED": "The payment is not permitted.",
			"OFFLINE_PIN_REQUIRED": "The card has been declined as it requires a PIN.",
			"ONLINE_OR_OFFLINE_PIN_REQUIRED": "The card has been declined as it requires a PIN.",
			"PAYMENT_INTENT_AUTHENTICATION_FAILURE": "We are unable to authenticate your payment method. Please choose a different payment method and try again.",
			"PICKUP_CARD": "The card cannot be used to make this payment (it is possible it has been reported lost or stolen).",
			"PIN_TRY_EXCEEDED": "The allowable number of PIN tries has been exceeded.",
			"PROCESSING_ERROR": "An error occurred while processing the card.",
			"REENTER_TRANSACTION": "The payment could not be processed by the issuer for an unknown reason.",
			"RESTRICTED_CARD": "The card cannot be used to make this payment (it is possible it has been reported lost or stolen).",
			"REVOCATION_OF_ALL_AUTHORIZATIONS": "The card has been declined for an unknown reason.",
			"REVOCATION_OF_AUTHORIZATION": "The card has been declined for an unknown reason.",
			"SECURITY_VIOLATION": "The card has been declined for an unknown reason.",
			"SERVICE_NOT_ALLOWED": "The card has been declined for an unknown reason.",
			"STOLEN_CARD": "The payment has been declined because the card is reported stolen.",
			"STOP_PAYMENT_ORDER": "The card has been declined for an unknown reason.",
			"TESTMODE_DECLINE": "A Stripe test card number was used.",
			"TRANSACTION_NOT_ALLOWED": "Your card does not support this type of purchase.",
			"TRY_AGAIN_LATER": "The card has been declined for an unknown reason.",
			"WITHDRAWAL_COUNT_LIMIT_EXCEEDED": "The customer has exceeded the balance or credit limit available on their card.",
			"INVALID_EXPIRY_YEAR_PAST": "Your card's expiration year is in the past.",
			"INCOMPLETE_EXPIRY": "Your card's expiration date is incomplete.",
			"INCOMPLETE_CVC": "Your card's security code is incomplete.",
			"INCOMPLETE_ZIP": "Your postal code is incomplete.",
			"INCOMPLETE_NUMBER": "Your card number is incomplete.",
			"SETUP_INTENT_AUTHENTICATION_FAILURE": "Card authentication is failed, Please try again!",
			"INVALID_EXPIRY_MONTH_PAST": "Your card's expiration date is in the past.",
			"RESOURCE_MISSING": "Your resource is missing, Please contact to our support team.",
			"REFUND_NOT_PROCESSED": "Refund not processed.",
			"REMAINING_CREDIT_NOT_FOUND": "Remaining credit not found.",
			"PLAN_PRICE_NOT_FOUND": "Plan price not found.",
			"TOTAL_CREDIT_NOT_FOUND": "Total credit not found.",
			"STRIPE_PLAN_ID_NOT_FOUND": "Plan update fail, please try again",
			"REQUEST_FAILED": "Request Failed.",
			"charge_already_refunded": "You have refunded for previous active plan.",
			"USER_NOT_SUBSCRIBE_TO_PLAN": "You are not subscribed to any of our plan.",
			"undefined": "Something went wrong",
			"PLEASE_TRY_AGAIN": "Please try again",
			"LIVE_MODE_TEST_CARD": "Test card is not allowed."
		}
	},
	"success": {
		"PASSWORD_CHANGE_SUCCESSFULLY": "Your password has been updated successfully.",
		"LINK_HAS_BEEN_SENT_TO_YOUR_EMAIL_FOR_RESET_PASSWORD": "Thanks, check your email for instructions to reset your password.",
		"PASSWORD_UPDATED": "Password updated successfully...!",
		"EMAIL_VERIFIED": "Email Verified",
		"FILE_SAVED_IN_DRIVE": "File Saved in Drive",
		"REGISTERED_SUCCESSFULLY": "You have been registered successfully.",
		"CHECK_YOUR_EMAIL": "Please check your email to find your login credentials",
		"PLAN_UPGRADED": "Your plan has been upgraded successfully.",
		"PLAN_SELECTED": "Plan Selected Successfully",
		"URL_MOVED_TO_ARCHIVE": "Url moved to archive successfully",
		"URL_UN_ARCHIVED_SUCCESS": "Url unarchived successfully",
		"REQUEST_SUBMITTED_SUCCESSFULLY": "Request submitted successfully.",
		"PROXY_GROUP_MAPPING_CREATED_SUCCESSFULLY": "Proxy assign successfully.",
		"INVITATION_ACCEPTED_SUCCESSFULLY": "A team invitation has been accepted successfully.",
		"INVITATION_DECLINED_SUCCESSFULLY": "A team invitation has been declined.",
		"PROXY_CREATED_SUCCESSFULLY": "Proxy created successfully.",
		"PROXY_UPDATED_SUCCESSFULLY": "Proxy updated successfully.",
		"PARSER_CREATED_SUCCESSFULLY": "Parser Created Successfully",
		"PARSER_UPDATED_SUCCESSFULLY": "Parser Updated Successfully",
		"PARSER_SUBSCRIPTION_CREATED_SUCCESSFULLY": "Parser Subscription Created Successfully",
		"PARSER_SUBSCRIPTION_DELETED_SUCCESSFULLY": "Parser Subscription Deleted Successfully",
		"USER_DATA_UPDATED_SUCCESSFULLY": "User Data Updated Successfully.",
		"WEBHOOK_CREATED_SUCCESSFULLY": "Webhook created successfully",
		"WEBHOOK_UPDATED_SUCCESSFULLY": "Webhook updated successfully",
		"CREATE_DATA_SET_SUCCESSFULLY": "Data Set created successfully",
		"UPDATED_DATA_SET_SUCCESSFULLY": "Data Set updated successfully",
		"DELETE_DATA_SET_SUCCESSFULLY": "Data Set deleted successfully",
		"THIS_PROXY_IS_UP": "This proxy is up.",
		"PARSER_CATEGORY_UPDATED_SUCCESSFULLY": "Parser category updated successfully",
		"PARSER_CATEGORY_DELETED_SUCCESSFULLY": "Parser category deleted successfully",
		"USER_ALREADY_VERIFIED": "User already verified with this email."
	},
	"home": {
		"allocated_credits": "Allocated credits",
		"remaining_credits": "Remaining credits",
		"used_credits": "Used credits",
		"success_requests": "Success requests",
		"failed_requests": "Failed requests",
		"allowed_concurrent_requests": "Allowed concurrent requests",
		"current_concurrent_requests": "Current concurrent requests",
		"current_plan": "Plan",
		"plan_expires_on": "Plan expires on",
		"copy_to_clipboard": "Copy to clipboard",
		"copied": "Copied",
		"good_morning": "Good morning",
		"good_afternoon": "Good afternoon",
		"good_evening": "Good evening",
		"copy": "Copy",
		"no_active_plan": "Your account have not any active Plan. Please click here to purchase plan.",
		"regenerate_access_token": "Regenerate access token",
		"regenerate_access_token_prompt": "Are you sure you want to regenerate access token?",
		"regenerate": "Regenerate",
		"cancel": "Cancel",
		"lifeTime": "Lifetime",
		"success": {
			"access_token_generated": "Access token regenerated successfully."
		},
		"trial_will_ends_in": "Trial will ends in",
		"days": "Days"
	},
	"common": {
		"save": "Save",
		"success": "Success",
		"fail": "Failed",
		"cancel": "Cancel",
		"previous": "Previous",
		"next": "Next",
		"en-US": "English",
		"de-DE": "German",
		"copyright_reserved": "Copyright All Rights Reserved © 2023",
		"errors": {
			"spaceAtStarts": " is invalid",
			"spaceAtEnds": " is invalid",
			"required": " is required",
			"countryInvalid": " is invalid",
			"professionInvalid": " is invalid",
			"designationInvalid": " is invalid"
		},
		"total": "Total",
		"notProcessed": "Not Processed",
		"selected": "Selected"
	},
	"header": {
		"userMenu": {
			"profile": "Profile",
			"changePassword": "Change Password",
			"logOut": "Log Out",
			"docs": "Docs"
		},
		"profile": "profile"
	},
	"auth": {
		"input": {
			"email": "Email address",
			"password": "Password",
			"firstName": "First name",
			"middleName": "Middle name",
			"lastName": "Last name",
			"currentPassword": "Current Password",
			"newPassword": "New Password",
			"confirmPassword": "Confirm Password",
			"code": "Code",
			"name": "Name",
			"cardInformation": "Card Information",
			"enterTeamName": "Enter team name",
			"referral": "How did you find us?",
			"other": "Other",
			"selectAny": "Please select any",
			"address": "Address",
			"state": "State",
			"countryCode": "Country",
			"postalCode": "Postal code",
			"city": "City",
			"zip": "Zip Code",
			"creditCardNumber": "Credit card number",
			"profession": "Profession",
			"designation": "Designation",
			"businessWebsite": "Business website",
			"termsMessageFirstPart": "By signing up you agree to our",
			"termsMessageSecondPart": "Terms & Conditions",
			"termsMessageThirdPart": "and to scrape in compliance with the Terms & Conditions of the websites you intend to scrape."
		},
		"validation": {
			"emailRequired": "Please enter email.",
			"emailReal": "Please enter a valid email.",
			"passwordRequired": "Please enter password.",
			"passwordLength": "Your password should have 8 characters.",
			"firstNameRequired": "Please enter First name.",
			"codeRequired": "Code is Required",
			"confirmPasswordRequired": "Please enter confirm password.",
			"nameIsRequired": "Please enter name.",
			"addressIsRequired": "Please enter address.",
			"stateIsRequired": "Please enter state.",
			"countryCodeIsRequired": "Please select country.",
			"postalCodeIsRequired": "Please enter Postal code.",
			"cityIsRequired": "Please enter city.",
			"zipIsRequired": "Please enter zip code.",
			"creditCardInformationIsRequired": "Credit card number is required",
			"pleaseSelectCountry": "Country",
			"teamNameRequired": "Team name is required",
			"businessWebsiteRequired": "Please enter business website",
			"bussinessWebsiteLength": "Your business website maximum 50 characters"
		},
		"button": {
			"login": "Log In",
			"register": "Register",
			"requestPassword": "Request Password",
			"updatePassword": "Update Password",
			"submit": "Submit",
			"goto_home": "Goto Home",
			"cancel": "Cancel"
		},
		"login": {
			"title": "Login",
			"subTitle": "Hello! Log in with your email.",
			"subTitleAppSumo": "Welcome Sumo-ling! Log in with your email.",
			"dontHaveAnAccount": "Don't Have an Account?",
			"alreadyHaveAnAccount": "Already Have an Account?",
			"forgotPassword": "Forgot Password"
		},
		"register": {
			"title": "Create an account",
			"invalid_plan_use_default": "You are attempting to sign up with the wrong plan. You're now on our Free plan, and you can upgrade later. Contact support@stubified.com for details.",
			"iAmATechnicalPerson": "I am a Technical Person, I want to Use for",
			"programmingLanguage": "Programming Language",
			"dashboard": "Dashboard",
			"chromeExtension": "Chrome Extension",
			"next": "Next",
			"back": "Back",
			"success": {
				"message": "You have been registered successfully."
			},
			"label": {
				"plan": "Plan"
			}
		},
		"forgotPassword": {
			"requestPassword": {
				"title": "Forgot Password",
				"subTitle": "Enter your email address and we’ll send a link to reset your password",
				"label": "Enter your email address",
				"rememberYourPassword": "Remember your Password?"
			},
			"updatePassword": {
				"title": "Update Password",
				"logoutFromAll": "You will be logout out from all session.",
				"areYouSure": "Are you sure you want to change password?",
				"button": {
					"updatePassword": "Update password",
					"cancel": "Cancel"
				}
			}
		},
		"changePassword": {
			"title": "Change password",
			"subTitle": "Please set a new password",
			"success": {
				"message": "Password Changed Successfully...!"
			}
		},
		"profile": {
			"title": "Profile",
			"changepassword": "Change password",
			"logoutFromAll": "You will be logout out from all session.",
			"areYouSure": "Are you sure you want to change password?",
			"success": {
				"message": "Your Profile has been updated successfully."
			},
			"warning": {
				"message": " You haven't verified your email yet"
			},
			"button": {
				"changepassword": "Change password",
				"cancel": "Cancel"
			},
			"verifyNow": "Verify now",
			"verificationMessage": "Thanks, check your email for instructions to verify your email."
		},
		"verifyEmail": {
			"title": "Verify Email",
			"code-tooltip": "Get this code from email",
			"verifying": "Verifying",
			"verified": "Verified",
			"verificationSuccess": "Email Verified"
		},
		"googleDrive": {
			"title": "Google Drive",
			"button": "Login With Google",
			"label": "Connected Email"
		},
		"thankYou": {
			"title": "Thank You!",
			"checkEmail": "Please check your email",
			"instruction": "for further instructions on how to complete your account setup",
			"trouble": "Having trouble?",
			"contactUs": "Contact us",
			"email_verification_link_has_been_sent": "Email verification link has been sent to",
			"cant_find_link_check_spam": "Can't find your link? Check your spam folder.",
			"please": "Please",
			"click_here": "Click here",
			"resend_verification_email": "to resend email verification details."
		},
		"invite": {
			"teamInvitation": "Team Invitation",
			"teamName": "Team name",
			"hasInvitedYouToTeam": "has invited you to Join the Team",
			"button": {
				"accept": "Accept",
				"decline": "Decline"
			}
		}
	},
	"generateToken": {
		"menu": "Usage",
		"accessToken": "Access Token",
		"label": {
			"sampleApiCode": "Sample API Code"
		},
		"button": {
			"save": "Save"
		},
		"apoCode": "curl '{{httpUrl}}/parse?access_token={{accessToken}}&url=https://google.com'"
	},
	"userPlan": {
		"title": "Upgrade",
		"menu": "Billing History",
		"input": {
			"cardName": "Name"
		},
		"validation": {
			"cardNameRequired": "Please enter name."
		},
		"button": {
			"buy": "Buy Now",
			"select": "Select",
			"pay": "Pay",
			"download": "Download",
			"next": "Next"
		},
		"success": {
			"upgrade": "Your plan has been upgraded successfully.",
			"select": "Your plan has been purchased successfully."
		},
		"upgradePlan": "Upgrade Plan",
		"concurrent_request": "Concurrent Request",
		"api_calls": "Credits",
		"no_js_rendering": "No JS Rendering",
		"js_rendering": "JS Rendering",
		"email_support": "Email Support",
		"priority_email_support": "Priority Email Support",
		"is_filter_and_sort_requests": "Filter and sort request page",
		"us_geo_targeting": "US Geotargeting",
		"eu_geo_targeting": "EU Geotargeting",
		"per_day": "Per Day",
		"per_week": "Per Week",
		"per_quarter": "Per Quarter",
		"per_annum": "Per Annum",
		"per_month": "Per Month",
		"one_time": "Lifetime",
		"no_plan_found": "No plan found",
		"users": "Users",
		"single_user": "Single user",
		"premium_proxy": "Premium Proxy",
		"standard_proxy": "Standard Proxy",
		"noGeoTargeting": "No Geotargeting",
		"usCaAndEuGeoTargeting": "US, CA & EU Geotargeting",
		"15PlusGeoTargeting": "15+ Geotargeting",
		"10GeoTargeting": "10 Geotargeting",
		"usGeoTargeting": "US Geo Targeting",
		"euGeoTargeting": "EU Geo Targeting",
		"exclusiveFeatures": "Exclusive Features",
		"allPlanInclusiveFeatures": "All Plans Inclusive Below Features",
		"aiBased": "AI Based",
		"proxyRotation": "Proxie Rotation",
		"captcha": "Captcha",
		"detection": "Detection",
		"antiBot": "Anti Bot",
		"automatic": "Automatic",
		"retries": "Retries",
		"desktopAndMobile": "Desktop and mobile user agents",
		"postRequest": "POST Request",
		"support": "Support",
		"customHeader": "Custom Header",
		"unlimited": "Unlimited",
		"bandwidth": "Bandwidth",
		"rendering": "Rendering",
		"premium": "Premium",
		"proxies": "Proxies",
		"professional": "Professional",
		"zapierSupport": "Zapier Integration support",
		"pabblySupport": "Pabbly Integration support",
		"dataExtraction": "Data extraction"
	},
	"url": {
		"title": "Requests",
		"menu": "Requests",
		"moveToArchive": "Move to archive",
		"thisWillMoveToArchive": "This will move selected urls to archive.",
		"areYouSureForArchive": "Are you sure you want to archive this urls?",
		"unArchive": "Unarchive urls",
		"thisWillUnArchive": "This will unarchive all selected urls.",
		"areYouSureForUnArchive": "Are you sure you want to unarchive this urls?",
		"no_record_found": "Ready to populate this table with your data? Get started now!",
		"chromeExtensionMessage": "This Parser only supports in chrome extension.",
		"input": {
			"url": "Enter Url",
			"automatic": "Automatic",
			"filter": {
				"property": "Property",
				"between": "between",
				"operator": "Operator",
				"value": "Value",
				"equals": "equals",
				"doesNotEqual": "does not equal",
				"lessThan": "less than",
				"greaterThan": "greater than",
				"lessThanOrEqual": "less than or equal to",
				"greaterThanOrEqual": "greater than or equal to",
				"contains": "contains",
				"pickDateRange": "Pick date range"
			}
		},
		"validation": {
			"validUrl": "Please enter valid url."
		},
		"column": {
			"url": "Url",
			"savedTo": {
				"title": "Saved To",
				"local": "Local",
				"drive": "Drive"
			},
			"status": "Status",
			"utilizedCredits": "Utilized Credits",
			"timeTakenInSeconds": "Time Taken In Seconds",
			"timeTaken": "Time Taken",
			"seconds": "(Seconds)",
			"requestBy": "Request By",
			"date": "Request On",
			"errorMessage": "Error Message",
			"countryCode": "Country Code",
			"isJsRender": "JS Render",
			"isPremium": "Premium",
			"isKeepHeaders": "Headers",
			"requestId": "Request Id",
			"isMobileView": "Mobile View",
			"errorCode": "Error Code",
			"errorDetail": "Error Detail",
			"parserSubscriptionRequestId": "Parser subscription id",
			"actions": "Actions",
			"name": "Name",
			"selector": "Selector",
			"selectorScript": "Selector script",
			"options": "Options",
			"formData": "Form Data",
			"formSelector": "Form Selector",
			"submitButtonSelector": "Submit button selector",
			"formSelectorScript": "Form Selector script",
			"submitButtonSelectorScript": "Submit button selector script",
			"formFields": "Form Fields",
			"value": "Value",
			"parserName": "Parser Name"
		},
		"button": {
			"parse": "Parse",
			"refresh": "Refresh",
			"newRequest": "New Request",
			"saveInDrive": "Save in Drive",
			"cancel": "Cancel",
			"actions": "Actions",
			"moveToArchive": "Move to archive",
			"unArchiveUrls": "Unarchive urls",
			"showArchive": "Show archive",
			"archive": "Archive",
			"unArchive": "Unarchive",
			"filter": {
				"apply": "Apply",
				"cancel": "Cancel"
			}
		},
		"advance-options": {
			"advanceOptions": "Advance Options",
			"sessionNumber": "Session Number",
			"countryCode": "Country Code",
			"render": "Render",
			"premium": "Premium",
			"keepHeaders": "Keep Headers",
			"customHeaders": "Custom Headers",
			"key": "key",
			"value": "value",
			"responseFormat": "Response Type",
			"size": "Total Images",
			"mobileView": "Mobile View",
			"selector": "Selector",
			"htmlSelector": "HTML Element Selector",
			"automatic": "Automatic",
			"customSelectors": "Actions",
			"option": "Options",
			"keyPlaceholder": "Selector/Script",
			"valuePlaceholder": "Value",
			"formdata": "Form Data",
			"formSelector": "Form",
			"formSelectorPlaceholder": "Form Selector/Script",
			"submitButtonSelector": "Submit button selector/script",
			"submitButtonSelectorLabel": "Submit button",
			"formSelectorScript": "Form selector script",
			"submitButtonSelectorScript": "Submit button selector script",
			"formdataValuePlaceholder": "Value",
			"script": "Script",
			"formFields": "Form Fields",
			"scrollToBottom": "Scroll To Bottom",
			"scrollTimeOut": "Timeout - milisecond"
		},
		"label": {
			"remainingCredit": "Remaining Credits",
			"requests": "Requests",
			"you_have_not_made_any_requests": "You have not made any request.",
			"filters": "Filters",
			"parserName": "Parser Name:",
			"parserSubscriptionId": "Parser Subscription Id:",
			"datasetRequestId": "Dataset Request Id:",
			"geoTargeting": "Geotargeting"
		}
	},
	"automation": {
		"title": "Create Automation",
		"menu": "Automation",
		"moveToArchive": "Move to archive",
		"thisWillMoveToArchive": "This will move selected urls to archive.",
		"areYouSureForArchive": "Are you sure you want to archive this urls?",
		"unArchive": "Unarchive urls",
		"thisWillUnArchive": "This will unarchive all selected urls.",
		"areYouSureForUnArchive": "Are you sure you want to unarchive this urls?",
		"input": {
			"url": "Enter Comma Separate Url's",
			"maxRetry": "Max Retry",
			"title": "Name",
			"unit": "automation Unit",
			"automatic": "Automatic",
			"automationOccurrence": "Automation Occurrence",
			"filter": {
				"property": "Property",
				"between": "between",
				"operator": "Operator",
				"value": "Value",
				"equals": "equals",
				"doesNotEqual": "does not equal",
				"lessThan": "less than",
				"greaterThan": "greater than",
				"lessThanOrEqual": "less than or equal to",
				"greaterThanOrEqual": "greater than or equal to",
				"contains": "contains",
				"pickDateRange": "Pick date range"
			}
		},
		"validation": {
			"validUrl": "Please enter valid url."
		},
		"column": {
			"url": "Url",
			"savedTo": {
				"title": "Saved To",
				"local": "Local",
				"drive": "Drive"
			},
			"status": "Status",
			"utilizedCredits": "Utilized Credits",
			"timeTakenInSeconds": "Time Taken In Seconds",
			"timeTaken": "Time Taken",
			"seconds": "(Seconds)",
			"requestBy": "Request By",
			"date": "Date",
			"errorMessage": "Error Message",
			"countryCode": "Country Code",
			"isJsRender": "JS Render",
			"isPremium": "Premium",
			"isKeepHeaders": "Headers",
			"requestId": "Request Id",
			"isMobileView": "Mobile View",
			"errorCode": "Error Code",
			"errorDetail": "Error Detail",
			"parserSubscriptionRequestId": "Parser subscription id",
			"actions": "Actions",
			"name": "Name",
			"selector": "Selector",
			"selectorScript": "Selector script",
			"options": "Options",
			"formData": "Form Data",
			"formSelector": "Form Selector",
			"submitButtonSelector": "Submit button selector",
			"formSelectorScript": "Form Selector script",
			"submitButtonSelectorScript": "Submit button selector script",
			"formFields": "Form Fields",
			"value": "Value"
		},
		"button": {
			"parse": "Parse",
			"createAutomation": "Create Automation",
			"createDraft": "Create Draft",
			"refresh": "Refresh",
			"newRequest": "New Request",
			"saveInDrive": "Save in Drive",
			"cancel": "Cancel",
			"actions": "Actions",
			"moveToArchive": "Move to archive",
			"unArchiveUrls": "Unarchive urls",
			"showArchive": "Show archive",
			"archive": "Archive",
			"unArchive": "Unarchive",
			"saveDraft": "Save Draft",
			"filter": {
				"apply": "Apply",
				"cancel": "Cancel"
			}
		},
		"advance-options": {
			"pabbly": "Pabbly",
			"zapier": "Zapier",
			"advanceOptions": "Advance Options",
			"runImmediately": "Run Immediately",
			"sessionNumber": "Session Number",
			"countryCode": "Country Code",
			"render": "Render",
			"premium": "Premium",
			"keepHeaders": "Keep Headers",
			"customHeaders": "Custom Headers",
			"key": "key",
			"value": "value",
			"responseFormat": "Response Type",
			"size": "Total Images",
			"mobileView": "Mobile View",
			"selector": "Selector",
			"htmlSelector": "HTML Element Selector",
			"automatic": "Automatic",
			"customSelectors": "Actions",
			"option": "Options",
			"keyPlaceholder": "Selector/Script",
			"valuePlaceholder": "Value",
			"formdata": "Form Data",
			"formSelector": "Form",
			"formSelectorPlaceholder": "Form Selector/Script",
			"submitButtonSelector": "Submit button selector/script",
			"submitButtonSelectorLabel": "Submit button",
			"formSelectorScript": "Form selector script",
			"submitButtonSelectorScript": "Submit button selector script",
			"formdataValuePlaceholder": "Value",
			"script": "Script",
			"formFields": "Form Fields",
			"scrollToBottom": "Scroll To Bottom",
			"scrollTimeOut": "Timeout - milisecond"
		},
		"label": {
			"url": "Enter Url's",
			"urlCount": "Url Count",
			"maxRetry": "Max Retry",
			"title": "Name",
			"automationOccurrence": "Automation Occurrence",
			"repeatIntervals": "Repeat Intervals",
			"selectInterval": "Select Interval",
			"repeatUntil": "Repeat Until",
			"remainingCredit": "Remaining Credits",
			"requests": "Schedule Requests",
			"you_have_not_made_any_requests": "You have not made any request.",
			"filters": "Filters",
			"parserName": "Parser Name:",
			"parserSubscriptionId": "Parser Subscription Id:",
			"datasetRequestId": "Dataset Request Id:",
			"geoTargeting": "Geotargeting"
		}
	},
	"myAutomation": {
		"view": "My Automation View",
		"title": "My Automation",
		"create": "Automate",
		"menu": "Automation",
		"moveToArchive": "Move to archive",
		"thisWillMoveToArchive": "This will move selected urls to archive.",
		"areYouSureForArchive": "Are you sure you want to archive this urls?",
		"unArchive": "Unarchive urls",
		"thisWillUnArchive": "This will unarchive all selected urls.",
		"areYouSureForUnArchive": "Are you sure you want to unarchive this urls?",
		"no_record_found": "No data? No problem! Automate your data collection and enjoy seamless extraction.",
		"selectAutomation": "Select Automation",
		"input": {
			"url": "Enter Url's",
			"title": "Name",
			"automationOccurrence": "Automation Occurrence",
			"automatic": "Automatic",
			"filter": {
				"property": "Property",
				"between": "between",
				"operator": "Operator",
				"value": "Value",
				"equals": "equals",
				"doesNotEqual": "does not equal",
				"lessThan": "less than",
				"greaterThan": "greater than",
				"lessThanOrEqual": "less than or equal to",
				"greaterThanOrEqual": "greater than or equal to",
				"contains": "contains",
				"pickDateRange": "Pick date range"
			}
		},
		"validation": {
			"validUrl": "Please enter valid url."
		},
		"column": {
			"totalRepetition": "Total Repetition",
			"success": "Success",
			"failed": "Failed",
			"country_code": "Country Code",
			"customSelectors": "Custom Selectors",
			"isScript": "Is Script",
			"device_type": "Device Type",
			"format": "Formate",
			"keepSelection": "Keep Selection",
			"render": "Render",
			"submitType": "local",
			"jobId": "Job Id",
			"isImmediateRequest": "is Immediate Request",
			"urlOptions": "Url Options",
			"createdAt": "Created At",
			"updatedAt": "Updated At",
			"id": "Id",
			"name": "Name",
			"lastExecutionTime": "Last Execution Time",
			"status": "Status",
			"automationOccurrence": "Automation Occurrence",
			"intervals": "Intervals",
			"url": "Url",
			"savedTo": {
				"title": "Saved To",
				"local": "Local",
				"drive": "Drive"
			},
			"utilizedCredits": "Utilized Credits",
			"timeTakenInSeconds": "Time Taken In Seconds",
			"timeTaken": "Time Taken",
			"seconds": "(Seconds)",
			"requestBy": "Request By",
			"date": "Date",
			"errorMessage": "Error Message",
			"countryCode": "Country Code",
			"isJsRender": "JS Render",
			"isPremium": "Premium",
			"isKeepHeaders": "Headers",
			"requestId": "Request Id",
			"isMobileView": "Mobile View",
			"errorCode": "Error Code",
			"errorDetail": "Error Detail",
			"parserSubscriptionRequestId": "Parser subscription id",
			"actions": "Actions",
			"selector": "Selector",
			"selectorScript": "Selector script",
			"options": "Options",
			"formData": "Form Data",
			"formSelector": "Form Selector",
			"submitButtonSelector": "Submit button selector",
			"formSelectorScript": "Form Selector script",
			"submitButtonSelectorScript": "Submit button selector script",
			"formFields": "Form Fields",
			"value": "Value",
			"maxRetry": "Max Retry"
		},
		"button": {
			"parse": "Parse",
			"automate": "Automate",
			"refresh": "Refresh",
			"newRequest": "New Request",
			"saveInDrive": "Save in Drive",
			"cancel": "Cancel",
			"actions": "Actions",
			"moveToArchive": "Move to archive",
			"unArchiveUrls": "Unarchive urls",
			"showArchive": "Show archive",
			"archive": "Archive",
			"unArchive": "Unarchive",
			"view": "View",
			"runDraft": "Run Draft",
			"viewDataset": "View Dataset",
			"filter": {
				"apply": "Apply",
				"cancel": "Cancel"
			}
		},
		"advance-options": {
			"pabbly": "Pabbly",
			"zapier": "Zapier",
			"advanceOptions": "Advance Options",
			"runImmediately": "Run Immediately",
			"sessionNumber": "Session Number",
			"countryCode": "Country Code",
			"render": "Render",
			"premium": "Premium",
			"keepHeaders": "Keep Headers",
			"customHeaders": "Custom Headers",
			"key": "key",
			"value": "value",
			"responseFormat": "Response Type",
			"size": "Total Images",
			"mobileView": "Mobile View",
			"selector": "Selector",
			"htmlSelector": "HTML Element Selector",
			"automatic": "Automatic",
			"customSelectors": "Actions",
			"option": "Options",
			"keyPlaceholder": "Selector/Script",
			"valuePlaceholder": "Value",
			"formdata": "Form Data",
			"formSelector": "Form",
			"formSelectorPlaceholder": "Form Selector/Script",
			"submitButtonSelector": "Submit button selector/script",
			"submitButtonSelectorLabel": "Submit button",
			"formSelectorScript": "Form selector script",
			"submitButtonSelectorScript": "Submit button selector script",
			"formdataValuePlaceholder": "Value",
			"script": "Script",
			"formFields": "Form Fields",
			"scrollToBottom": "Scroll To Bottom",
			"scrollTimeOut": "Timeout - milisecond"
		},
		"label": {
			"repeatIntervals": "Repeat Intervals",
			"selectInterval": "Select Interval",
			"automationOccurrence": "Automation Occurrence",
			"remainingCredit": "Remaining Credits",
			"requests": "Schedule Requests",
			"you_have_not_made_any_requests": "You have not made any request.",
			"filters": "Filters",
			"parserName": "Parser Name:",
			"parserSubscriptionId": "Parser Subscription Id:",
			"datasetRequestId": "Dataset Request Id:",
			"geoTargeting": "Geotargeting"
		}
	},
	"billing-info": {
		"dateTitle": "Invoice Date",
		"amountTitle": "Amount",
		"planNameTitle": "Plan Name",
		"invoice": "Invoice"
	},
	"admin": {
		"title": "Users",
		"menu": "Users",
		"admin_menu": "Admin",
		"plan_menu": "Plans",
		"plan_group": "Plan Groups",
		"coupon": "Coupons",
		"user_plan": "User Plan",
		"button": {
			"downloadCsv": "Download CSV"
		},
		"column": {
			"id": "Id",
			"email": "Email",
			"firstName": "First name",
			"createdAt": "Created At",
			"remainingCredit": "Remaining Credit",
			"planStatus": "Plan Status",
			"verifiedEmail": "Verified Email",
			"plan": "Plan",
			"price": "Price",
			"plan_expire_date": "Plan expire date",
			"allocated_credits": "Allocated credits",
			"accessToken": "Access Token",
			"teamId": "Team Id",
			"designation": "Designation",
			"profession": "Profession",
			"country": "Country",
			"businessWebsite": "Business website",
			"teamName": "Team Name",
			"isActive": "Active?",
			"isDeleted": "Deleted?",
			"userId": "User Id",
			"wantToUseFor": "I want to use for"
		},
		"filter": {
			"yes": "Yes",
			"no": "No"
		}
	},
	"subscription": {
		"id": "ID",
		"createdAt": "Created At",
		"startDate": "Start Date",
		"endDate": "End Date",
		"nickName": "Nick Name",
		"amount": "Amount",
		"status": "Status"
	},
	"payment_method": {
		"payment": "Payment Methods",
		"brand": "Brand",
		"country": "Country",
		"expiry_month": "Expiry Month",
		"expiry_year": "Expiry Year",
		"card_no": "Card No.",
		"id": "ID",
		"payment_type": "Payment Type",
		"customer_id": "Customer ID",
		"funding": "Funding"
	},
	"payment_table": {
		"payment_intent": "Payment Intents",
		"payment_intent_id": "Payment Intent ID",
		"amount": "Amount",
		"payment_method_type": "Payment Method Type",
		"paid": "Paid",
		"description": "Description",
		"invoice_url": "Invoice Url",
		"status": "Status"
	},
	"adminPlan": {
		"title": "Plans",
		"labelNotNull": "Label can not be null.",
		"creditsNotNull": "Credits call can not be null.",
		"conCurrentRequestNotNull": "Concurrent Request can not be null.",
		"priceNotNull": "Price can not be null.",
		"selectPlanPeriod": "Please select plan period.",
		"addNewPlan": "Create",
		"stripePlanName": "Stripe Plan Name",
		"nameCannotBeNull": "Name can not be null.",
		"nameNotHaveSpaces": "Name can not have any space.",
		"labelCannotBeNull": "Label can not be null.",
		"creditNotNull": "Credit can not be null.",
		"conRequestNotNull": "Concurrent Request can not be null.",
		"maxUserCannotBeNull": "Max user can not be null.",
		"label": {
			"credit": "Credits",
			"totalCredits": "Total Credits",
			"totalConCurrentRequests": "Total Concurrent Requests",
			"price": "Price",
			"maxUserInTeam": "Max Users in Team",
			"period": "Period",
			"isPlanUpgradable": "Is plan upgradable?",
			"euGeoTargeting": "EU Geo Targeting",
			"usGeoTargeting": "US Geo Targeting",
			"allGeoTargeting": "All Geo Targeting",
			"tenGeoTargeting": "Ten Geo Targeting",
			"jsRendering": "JS Rendering",
			"residentialProxies": "Residential Proxies",
			"standardProxies": "Standard Proxies",
			"emailSupport": "Email Support",
			"priorityEmailSupport": "Priority Email Support",
			"filterAndSortRequests": "Filter and sort request page",
			"planLabel": "Plan Label",
			"planPrice": "Plan Price",
			"concurrentRequests": "Concurrent Requests",
			"planName": "Plan Name",
			"currency": "Currency",
			"planType": "Plan Type",
			"cancel_at_period_end": "Cancel At Period End",
			"interval_count": "Interval Count",
			"viewUser": "View User"
		},
		"button": {
			"createNewPlan": "Create new plan",
			"update": "Update",
			"disablePlan": "Disable plan",
			"enablePlan": "Enable plan"
		},
		"option": {
			"daily": "Daily",
			"weekly": "Weekly",
			"monthly": "Monthly",
			"yearly": "Yearly",
			"oneTime": "Onetime"
		},
		"input": {
			"enterPlanLabel": "Enter a plan label",
			"enterCredits": "Enter Credits",
			"enterConRequests": "Enter Concurrent Requests",
			"enterPlanPrice": "Enter plan price",
			"enterMaxUserPerPlan": "Enter max users per plan",
			"enterPlanName": "Enter plan name",
			"maxConCurrentRequests": "Max Concurrent Requests"
		},
		"select": {
			"selectPlanPeriod": "Select plan period",
			"selectCountry": "Select country"
		}
	},
	"receipt": {
		"label": {
			"invoice": "Invoice",
			"date_of_issue": "Date of issue",
			"due_date": "Due Date",
			"bill_to": "Bill to",
			"description": "Description",
			"qty": "Qty",
			"unit_price": "Unit price",
			"amount": "Amount",
			"subtotal": "Subtotal",
			"amount_due": "Amount due",
			"thank_you_for_your_business": "Thank you for your business",
			"powered_by_stubified": "Powered by Stubified",
			"failed_to_download_receipt": "Failed to download payment receipt. Please try again.",
			"due": "due"
		}
	},
	"admin_proxy": {
		"title": "Proxies",
		"menu": "Proxies",
		"button": {
			"createProxy": "Create Proxy",
			"downloadCsv": "Download CSV",
			"archive": "Archive",
			"cancel": "Cancel"
		},
		"column": {
			"url": "Url",
			"country": "Country",
			"isUp": "is proxy up?",
			"checkProxy": "Check proxy",
			"actions": "Actions",
			"type": "Type",
			"lastChecked": "Last checked",
			"createdAt": "Created At",
			"failAttemptCount": "Failed Count",
			"id": "Id",
			"provider": "Provider",
			"description": "Description",
			"proxyId": "Proxy Provider ID",
			"providerName": "Proxy Provider Name",
			"paymentType": "Payment Type",
			"authenticationType": "Authentication Type"
		},
		"filter": {
			"yes": "Yes",
			"no": "No",
			"url": "URL",
			"scraperApi": "SCRAPER API",
			"tor": "TOR",
			"premiumProxy": "Premium proxy"
		}
	},
	"adminParser": {
		"title": "Parser",
		"menu": "Parser",
		"button": {
			"createProxy": "Create Proxy",
			"downloadCsv": "Download CSV",
			"archive": "Archive",
			"cancel": "Cancel"
		},
		"column": {
			"id": "Id",
			"name": "Name",
			"userId": "UserId",
			"teamId": "TeamId",
			"userName": "User Name",
			"teamName": "Team Name",
			"responseType": "Response Type",
			"type": "Type",
			"status": "Status",
			"description": "Short Description",
			"actions": "Actions",
			"requestId": "RequestId",
			"isDeleted": "isDeleted"
		},
		"filter": {
			"csv": "Csv",
			"json": "Json",
			"html": "Html",
			"xlsx": "Xlsx",
			"url": "URL",
			"scraperApi": "SCRAPER API",
			"tor": "TOR",
			"premiumParser": "Premium parser",
			"one": 1,
			"zero": 0
		}
	},
	"proxyCreateEdit": {
		"create": "Create",
		"edit": "Edit",
		"proxyUrl": "Proxy url",
		"urlRequired": "Url is required",
		"countryCodeRequired": "Country code is required",
		"enterCountry": "Enter country",
		"country": "Country",
		"enterUrl": "Enter Url",
		"enterProviderName": "Enter Provider Name",
		"provider": "Provider",
		"isUp": "is proxy up?",
		"proxyTypeRequired": "Proxy type required",
		"description": "Description",
		"proxyType": "Proxy Type",
		"enterDescription": "Enter Description",
		"selectType": "Select Proxy Type",
		"button": {
			"create": "Create",
			"update": "Update",
			"cancel": "Cancel"
		},
		"nameRequired": "Name is required",
		"websiteRequired": "Website is required",
		"websiteLength": "Your website should contain maximum 50 characters",
		"whiteListTypeRequired": "Whitelist type is required",
		"paymentTypeRequired": "Payment type is required"
	},
	"teams": {
		"menu": "Teams",
		"noRecordFound": "Collaborate with your team to unlock the power of data together!",
		"addNewTeam": "Add new Team",
		"teamName": "Team name",
		"teamNameRequired": "Please enter Team name.",
		"deleteTeam": "Delete Team",
		"deleteTeamPrompt": "Are you sure you want to delete this team?",
		"button": {
			"delete": "Delete",
			"viewUsers": "View Users",
			"invite": "Invite",
			"edit": "Edit",
			"cancel": "Cancel"
		},
		"column": {
			"teamName": "Team name",
			"teamUsers": "Team users",
			"actions": "Actions"
		}
	},
	"adminTeams": {
		"menu": "Teams",
		"noRecordFound": "No record found",
		"addNewTeam": "Add new Team",
		"teamName": "Team name",
		"teamNameRequired": "Please enter Team name.",
		"deleteTeam": "Delete Team",
		"deleteTeamPrompt": "Are you sure you want to delete this team?",
		"button": {
			"delete": "Delete",
			"viewUsers": "View Users",
			"invite": "Invite",
			"edit": "Edit",
			"cancel": "Cancel"
		},
		"column": {
			"teamName": "Team name",
			"teamUsers": "Team users",
			"actions": "Actions",
			"id": "Id",
			"planGroupName": "Plan Group Name",
			"planName": "Plan Name",
			"planGroupId": "Plan Group Id",
			"planId": "Plan Id",
			"stripeCustomerId": "Stripe Customer Id"
		}
	},
	"teamMembers": {
		"title": "Team Members",
		"deleteMember": "Delete Member",
		"userEmail": "User Email",
		"noRecordFound": "Collaborate with your team to unlock the power of data together!",
		"deleteUserPrompt": "Are you sure you want to delete this user?",
		"userDeleted": "A team member has been removed successfully.",
		"column": {
			"username": "User name",
			"email": "Email",
			"accessToken": "Access Token",
			"actions": "Actions"
		},
		"button": {
			"delete": "Delete",
			"cancel": "Cancel"
		}
	},
	"teamEdit": {
		"editTeam": "Edit",
		"teamName": "Team name",
		"teamNameRequired": "Team name is required",
		"teamNameUpdated": "Your Team has been updated successfully.",
		"teamNameMaxCharacters": "Team name should contain maximum 30 characters",
		"button": {
			"update": "Update",
			"cancel": "Cancel"
		},
		"input": {
			"enterTeamName": "Enter team name",
			"giveUsfeedback": "Give us feedback"
		},
		"options": {
			"notUsingAtAll": "Not using enough at all",
			"missingFeature": "Missing feature we need",
			"switchingProduct": "Switching to different product",
			"tooExpensive": "Too expensive",
			"difficultToSetup": "Difficult to setup",
			"other": "Other"
		},
		"sorryToSeeYouGo": "Sorry to see you go. Do you mind giving us feedback so we can improve. ",
		"planExpiresOn": "Your plan will be cancelled on ",
		"cancelSubscription": "Cancel subscription",
		"yourRefundWillBeCredited": "Your refund amount ${{refundAmount}} will be credit into original source.",
		"feedbackLength": "Maximum 200 characters only.",
		"pleaseSelectOne": "Please select one",
		"thereWillNoRefund": "There will be no refund",
		"planExpiryDate": "Plan expiry date",
		"dismiss": "Dismiss"
	},
	"invitations": {
		"title": "Invitations",
		"pendingInvitations": "Pending Invitations",
		"acceptedInvitations": "Accepted Invitations",
		"declinedInvitations": "Declined Invitations",
		"menu": "Invitations",
		"noRecordFound": "New team invites will appear here when available.",
		"acceptInvitation": "Accept Invitation",
		"teamName": "Team name",
		"accepted": "Accepted",
		"declined": "Declined",
		"accept": "Accept",
		"acceptInvitationPrompt": "Are you sure you want to accept this invitation?",
		"declineInvitationPrompt": "Are you sure you want to decline this invitation?",
		"declineInvitation": "Decline Invitation",
		"column": {
			"teamName": "Team name",
			"actions": "Actions"
		},
		"button": {
			"accept": "Accept",
			"decline": "Decline",
			"cancel": "Cancel"
		},
		"success": {
			"teamInvitationDeclined": "A team invitation has been declined.",
			"teamInvitationAccepted": "A team invitation has been accepted successfully."
		}
	},
	"teamInvite": {
		"title": "Team Invites",
		"invite_member": "Invite Member",
		"pending": "Pending",
		"accepted": "Accepted",
		"declined": "Declined",
		"noRecordFound": "Collaborate with your team to unlock the power of data together!",
		"column": {
			"email": "Email",
			"status": "Status",
			"username": "User name"
		},
		"form": {
			"email": "Email",
			"emailPlaceholder": "Enter email",
			"buttons": {
				"invite": "Invite",
				"cancel": "Cancel"
			},
			"errors": {
				"email_required": "Please enter email.",
				"email_invalid": "Please enter a valid email."
			},
			"success": {
				"user_invited_successfully": "A team invitation has been sent successfully to User."
			}
		}
	},
	"addOns": {
		"title": "Add Ons",
		"menu": "Add Ons",
		"connect": "Connect",
		"credit": "Credit",
		"free": "Free",
		"buttons": {
			"back": "Back"
		},
		"zapier": {
			"title": "Zapier",
			"apiKey": "API key",
			"copyToClipboard": "Copy to clipboard",
			"copied": "Copied"
		},
		"pabbly": {
			"title": "Pabbly",
			"apiKey": "API key",
			"copyToClipboard": "Copy to clipboard",
			"copied": "Copied"
		}
	},
	"planView": {
		"existingPlan": "Existing Plan",
		"newPlan": "New Plan",
		"remainingCredits": "Remaining credits",
		"refundForLeftCredits": "Refund for the remaining credits",
		"checkout": "Checkout",
		"refundDetails": "Refund Details",
		"redeem": "Redeem",
		"billingAddress": "Billing address",
		"promoCode": "Promo Code",
		"remove": "Remove",
		"applied": "Applied",
		"totalPayableAmount": "Total Payable Amount",
		"discount": "Discount",
		"trial": "Trial",
		"creditCard": "CREDIT / DEBIT CARD",
		"paypal": "PAYPAL",
		"payment": "Payment"
	},
	"admin_plan": {
		"advanceOptions": "Advance options",
		"cancel_at_period_end": "Cancel at period end",
		"interval_option": "Interval Option"
	},
	"adminProxyGroup": {
		"title": "Admin Proxy Group",
		"menu": "Proxy Group",
		"column": {
			"name": "Proxy Group Name",
			"mappingCount": "Proxy Mapping Count",
			"domainCount": "Domain Proxy Count"
		}
	},
	"adminProxyProvider": {
		"title": "Proxy Provider",
		"selectProxyProvider": "Select Proxy Provider",
		"proxyProviderRequired": "Proxy Provider Required",
		"add": "Add",
		"edit": "Edit",
		"column": {
			"name": "Name",
			"website": "Url",
			"description": "Description",
			"whiteListType": "Whitelist Type",
			"paymentType": "Payment Type"
		},
		"type": {
			"ip": "IP",
			"usernamePassword": "Username & Password",
			"none": "None",
			"oneTime": "One Time",
			"recurring": "Recurring"
		}
	},
	"adminUrls": {
		"title": "URLs",
		"menu": "URLs",
		"column": {
			"url": "Url",
			"status": "Status",
			"timeTakenByParse": "Time Taken Parse",
			"timeTakenByExecution": "Time Taken Execution",
			"date": "Date",
			"proxy": "Proxy",
			"parsedDate": "Parsed Date",
			"countryCode": "Country Code",
			"isJsRender": "JS Render",
			"isPremium": "Premium",
			"isKeepHeaders": "Headers",
			"requestId": "Request Id",
			"utilizedPabblyCredits": "Pabbly Credits",
			"requestBy": "Request By",
			"teamId": "TeamId",
			"attempts": "Attempts",
			"isBrowserLess": "BrowserLess",
			"errorCode": "Error Code",
			"errorMessage": "Error Message",
			"errorType": "Error Type",
			"isMobileView": "Mobile View",
			"urlId": "Url Id",
			"ipAddress": "IP Address",
			"userAgent": "User Agent",
			"clientName": "Client Name",
			"clientVersion": "Client Version",
			"actions": "Actions",
			"name": "Name",
			"selector": "Selector",
			"selectorScript": "Selector script",
			"options": "Options",
			"formData": "Form Data",
			"formSelector": "Form Selector",
			"submitButtonSelector": "Submit button selector",
			"formSelectorScript": "Form Selector script",
			"submitButtonSelectorScript": "Submit button selector script",
			"formFields": "Form Fields",
			"value": "Value"
		}
	},
	"filter": {
		"label": "Filters",
		"property": "Property",
		"between": "Between",
		"operator": "Operator",
		"value": "Value",
		"eq": "Equals",
		"ne": "Does not equal",
		"lt": "Less than",
		"gt": "Greater than",
		"lte": "Less than or equal to",
		"gte": "Greater than or equal to",
		"iLike": "Like",
		"is": "Null",
		"null": "Null",
		"not": "Not Null",
		"in": "In",
		"notIn": "Not In",
		"startsWith": "Starts With",
		"endsWith": "Ends With",
		"eis": "Is Empty",
		"pickDateRange": "Pick date range",
		"true": "True",
		"yes": "Yes",
		"no": "No",
		"false": "False",
		"button": {
			"apply": "Apply",
			"cancel": "Cancel"
		}
	},
	"urlSettings": {
		"url": "URL",
		"type": "Type",
		"selectType": "Select type",
		"typeRequired": "Type is required",
		"createdAt": "Created At",
		"actions": "Actions",
		"delete": "Delete",
		"edit": "Edit",
		"deleteUrl": "Delete Url Setting",
		"deleteWarning": "Are you sure you want to delete this Url Setting?",
		"urlSetting": "Url Setting",
		"create": "Create",
		"filter": "Filter",
		"id": "ID",
		"cancel": "Cancel",
		"options": "Option"
	},
	"parserCategoryFields": {
		"deleteCategory": "Delete Parser Category",
		"nameParser": "Parser Category",
		"deleteWarning": "Are you sure you want to delete this Parser Category?"
	},
	"assignProxy": {
		"proxyGroup": "Proxy Group",
		"placeholder": "Select Proxy Group"
	},
	"adminUrlAttempts": {
		"menu": "URL Attempts",
		"title": "URL Attempts"
	},
	"proxyProvider": {
		"title": "Proxy Provider",
		"menu": "Proxy Provider"
	},
	"pathTitle": {
		"dashboard": "Dashboard",
		"automation": "Automation",
		"myAutomation": "My Automation",
		"profile": "Profile",
		"plans": "Plans",
		"users": "Users",
		"planGroup": "Plan Group",
		"urls": "URLs",
		"UrlAttempts": "URL Attempts",
		"statistics": "Statistics",
		"urlSettings": "URL Settings",
		"parserCategory": "Parser Category",
		"proxies": "Proxies",
		"proxiesGroup": "Proxies Group",
		"request": "Requests",
		"addOns": "Add Ons",
		"billingHistory": "Billing History",
		"teams": "Teams",
		"invitation": "Invitations",
		"logout": "Logout",
		"login": "Login",
		"register": "Register",
		"forgotPassword": "Forgot Password",
		"modifyPassword": "Modify Password",
		"registerStep2": "Register Step 2",
		"parser": "Parser",
		"coupon": "Coupons",
		"docs": "Docs",
		"subscription": "Subscriptions",
		"Category": "Category",
		"dataset": "Data Set",
		"parserSubscription": "Parser Subscriptions",
		"payments": "Stripe Payments",
		"userPlan": "User Plan",
		"removalRequest": "Removal Request",
		"inactiveUsers": "Inactive Users",
		"logs": "Logs",
		"userStatistics": "User",
		"urlStatistics": "Url",
		"instagram": "Instagram"
	},
	"docs": {
		"base": "Bash",
		"php": "PHP",
		"python": "Python",
		"nodeJs": "Node Js",
		"readMore": "Read More",
		"tutorials": "Tutorials"
	},
	"stats": {
		"stat": "Statistics",
		"topUser": "Top User Requests",
		"topRequest": "Top Requests",
		"pickRange": "Pick Range",
		"proxyUpCount": "Proxy Up Count",
		"proxyDownCount": "Proxy Down Count",
		"urlErrorCode": "Top Urls Error Code",
		"errorMessage": "Error Message",
		"count": "Count",
		"statusCode": "Top Urls Status",
		"status": "Status",
		"failed": "Failed",
		"success": "Success",
		"notProcessed": "Not Processed",
		"planGroup": "Plan Groups",
		"planGroupName": "Plan Group Name",
		"teamCount": "Team Count",
		"plans": "Plans",
		"planName": "Plan Name",
		"country": "Country",
		"profession": "Profession",
		"designation": "Designation",
		"referral": "How did you find us?",
		"domain": "Domain",
		"wantToUseFor": "I want to use for"
	},
	"parserCategory": {
		"uniqueName": "Unique Name",
		"nameRequired": "Name is required",
		"uniqueNameRequired": "Unique Name is required"
	},
	"parser": {
		"title": "Parser",
		"myParser": "My Parser",
		"menu": "Parser",
		"create": "Create",
		"edit": "Edit",
		"name": "name",
		"update": "Update",
		"manyParser": "Parser",
		"parserLabel": "Parser (Extract JSON/CSV/XLSX)",
		"tryNow": "Try now",
		"loading": "Loading",
		"ALL_STEPS_COMPLETED": "You have completed all steps.",
		"PLEASE_COMPLETE_ALL_STEPS": "Please complete all steps.",
		"createdBy": "Created By",
		"createdOn": "Created On",
		"id": "Id",
		"thereIsNoParser": "There is no parser.",
		"clickHereToCreateNewOne": "Please click here to create one.",
		"thereIsNoSubscribedParser": "You have not subscribed to any parser.",
		"clickHereToTryParser": "Please click here to try parser.",
		"fileName": "File name: ",
		"subscribedOn": "Subscribed on",
		"credits": "Credits",
		"search": "Search",
		"prebuiltParser": "Prebuilt-Parser",
		"requestId": "Request Id",
		"enterValue": "Enter Value",
		"required": "Required",
		"labelName": "Label Name",
		"editDynamicInput": "Edit Dynamic Input",
		"cancel": "Cancel",
		"enterLabel": "Enter Label",
		"addDynamicInput": "Add Dynamic Input",
		"add": "Add",
		"dynamicInputs": "Dynamic Inputs",
		"error": {
			"required": " is required",
			"invalid": " is invalid"
		},
		"input": {
			"name": "Please Enter Parser Name",
			"description": "Please Enter Parser Description",
			"file": "Please Select File Type",
			"code": "Please Enter Parser Code",
			"keyword": "Press enter to add multiple keywords",
			"sampleUrls": "Please Enter Sample Urls",
			"usage": "Please Enter Usage",
			"metaDescription": "Please Enter Meta Description",
			"metaTitle": "Please Enter Meta Title",
			"slug": "Please Enter Slug",
			"metaCanonical": "Please Enter Meta Canonical"
		},
		"label": {
			"name": "Name",
			"description": "Short Description",
			"advanceDescription": "Long Description",
			"responseType": "Response Type",
			"file": "Select File",
			"type": "Type",
			"status": "Status",
			"code": "Code",
			"active": "Active",
			"deActive": "De-active",
			"private": "Private",
			"public": "Public",
			"other": "Other",
			"response": "Response",
			"htmlSample": "HTML Sample",
			"sampleFile": "Download Sample File",
			"selectParser": "Select Parser",
			"noFileChoosen": "No File Chosen",
			"areYouSureToSubscribe": "Are you sure you want to subscribe this parser?",
			"areYouSureToUnSubscribe": "Are you sure you want to unsubscribe this parser?",
			"fileShouldLessThanMB": "File size should be less than 1 MB",
			"fileShouldBeJS": "Only .js file allowed, please select another one",
			"keyword": "Keywords",
			"selectCategories": "Categories",
			"keywordLength": "Keyword should contain maximum 100 characters",
			"viewAllParsers": "View all parsers",
			"modified": "Modified on",
			"true": "True",
			"false": "False",
			"results": "Results",
			"filteredBy": "filtered by",
			"searchParserName": "Search parser name",
			"parserName": "Parser Name",
			"all": "All",
			"usage": "Usage",
			"sampleUrls": "Sample Urls",
			"output": "Output",
			"outputImage": "Output Image",
			"metaDescription": "Meta Description",
			"metaTitle": "Meta Title",
			"metaCanonical": "Meta Canonical",
			"slug": "Slug",
			"parserIcon": "Parser Icon",
			"isPublishWordPress": "Publish on wordpress?"
		},
		"button": {
			"createNewParser": "Create New Parser",
			"create": "Create",
			"update": "Update",
			"view": "View",
			"save": "Save",
			"validate": "Validate",
			"cancel": "Cancel",
			"loadMore": "Load More",
			"subscribe": "Subscribe",
			"unSubscribe": "Unsubscribe",
			"subscribed": "Subscribed",
			"back": "Back"
		}
	},
	"adminCoupon": {
		"coupon": "Coupons",
		"create": "Create",
		"name": "Name",
		"id": "Id",
		"type": "Type",
		"percentageDiscount": "Percentage Discount",
		"fixedAmountDiscount": "Fixed Amount Discount",
		"percentageOff": "Percentage Off",
		"discountAmount": "Discount Amount",
		"applyToSpecificProduct": "Apply to specific product",
		"duration": "Duration",
		"redemptionLimit": "Redemption limit",
		"button": {
			"create": "Create",
			"cancel": "Cancel",
			"delete": "Delete"
		}
	},
	"webhooks": {
		"title": "Webhook",
		"create": "Create",
		"edit": "Edit",
		"webhookName": "Webhook name",
		"label": {
			"webhookName": "Name",
			"description": "Description",
			"url": "Url",
			"active": "Active",
			"inactive": "Inactive",
			"status": "Status",
			"name": "Name",
			"id": "Id",
			"createdAt": "Created At",
			"userId": "User Id",
			"teamId": "Team Id",
			"webhookRequestId": "Webhook Request Id",
			"isVerified": "Is Verified",
			"teamName": "Team name",
			"selectWebhook": "Select Webhook",
			"webhookId": "Webhook Id",
			"deleteWebhook": "Are you sure you want to delete this webhook?"
		},
		"input": {
			"enterWebhookName": "Please Enter Name",
			"enterWebhookUrl": "Please Enter Url",
			"enterWebhookDescription": "Please Enter Description"
		},
		"error": {
			"required": " is required",
			"invalid": " is invalid"
		},
		"button": {
			"create": "Create",
			"update": "Update",
			"cancel": "Cancel",
			"delete": "Delete"
		}
	},
	"dataset": {
		"menu": "Dataset",
		"title": "Dataset",
		"create": "Create",
		"edit": "Edit",
		"view": "View",
		"nameRequired": "Data set is required",
		"namePlaceholder": "Name",
		"jsonKey": "JSON Key",
		"datasetLabel": "Data set",
		"selectDataset": "Select Data set",
		"no_record_found": "Don't have a dataset yet? Create one now and organize your data efficiently!",
		"dropDownErrorMessage": "For Creating A Dataset Enter A Valid Name",
		"newDataSet": "Create New Dataset For This Dataset",
		"dropDown": "Please Select Dataset",
		"column": {
			"name": "Name",
			"requestId": "Request Id",
			"createdAt": "Created At",
			"jsonKey": "JSON Key"
		},
		"button": {
			"submit": "Submit",
			"cancel": "Cancel",
			"delete": "Delete"
		},
		"deleteModel": {
			"headeing": "Delete Dataset",
			"title": "Dataset Name",
			"description": "Are you sure you want to delete this dataset?"
		}
	},
	"adminDataset": {
		"dataset": "Dataset",
		"menu": "Dataset",
		"title": "Dataset",
		"create": "Create",
		"edit": "Edit",
		"view": "View",
		"nameRequired": "Data set is required",
		"namePlaceholder": "Name",
		"jsonKey": "JSON Key",
		"datasetLabel": "Data set",
		"selectDataset": "Select Data set",
		"no_record_found": "Don't have a dataset yet? Create one now and organize your data efficiently!",
		"dropDownErrorMessage": "For Creating A Dataset Enter A Valid Name",
		"newDataSet": "Create New Dataset For This Dataset",
		"dropDown": "Please Select Dataset",
		"column": {
			"name": "Name",
			"requestId": "Request Id",
			"createdAt": "Created At",
			"jsonKey": "JSON Key"
		},
		"button": {
			"submit": "Submit",
			"cancel": "Cancel",
			"delete": "Delete"
		},
		"deleteModel": {
			"headeing": "Delete Dataset",
			"title": "Dataset Name",
			"description": "Are you sure you want to delete this dataset?"
		}
	},
	"adminParserSubscription": {
		"menu": "Parser Subscriptions",
		"title": "Parser Subscriptions"
	},
	"adminFcmNotification": {
		"fcmNotification": "Fcm Notification"
	},
	"adminStripePayments": {
		"menu": "Stripe Payments",
		"title": "Payments",
		"column": {
			"id": "Id",
			"amount": "Amount",
			"customer": "Customer",
			"currency": "Currency",
			"charges": "Charges",
			"refunded": "Refunded",
			"paymentMethod": "Payment Method",
			"status": "Status",
			"createdAt": "Created At",
			"chargesId": "charges Id",
			"failureCode": "Failure Code",
			"failureMessage": "Failure Message",
			"lastPaymentError": "last payment error",
			"invoice": "Invoice",
			"customerId": "Customer Id",
			"endingBefore": "Ending Before(Id)",
			"Limit": "Limit"
		}
	},
	"adminWebhook": {
		"webhook": "Webhooks"
	},
	"adminUserPlan": {
		"title": "User Plan",
		"column": {
			"id": "Id",
			"isLifeTime": "isLifeTime",
			"metaData": "Meta Data",
			"remainingCredit": "Remaining Credit",
			"totalCredit": "Total Credit",
			"planId": "Plan Id",
			"userId": "User Id",
			"teamId": "Team Id",
			"status": "Status",
			"createdAt": "Created on",
			"creditResetDate": "Credit Reset Date",
			"email": "Email",
			"planName": "Plan Name"
		}
	},
	"adminRemovalRequest": {
		"menu": "Removal Request",
		"title": "User Removal Request",
		"column": {
			"email": "Email",
			"reason": "Reason",
			"description": "Description",
			"userId": "User Id",
			"createdAt": "Request On"
		}
	},
	"inactiveUsers": {
		"teamId": "Team Id",
		"userEmail": "User Email"
	},
	"adminUserStatistics": {
		"statistics": "User Statistics"
	},
	"adminUrlStatistics": {
		"topFailDomain": "Top Fail Domains",
		"urlStatistics": "Url Statistics"
	},
	"no-records-found": {
		"request": "Ready to populate this table with your data? Get started now!",
		"automation": "No data? No problem! Automate your data collection and enjoy seamless extraction.",
		"dataset": "Don't have a dataset yet? Create one now and organize your data efficiently!"
	},
	"adminLogs": {
		"menu": "Logs",
		"title": "Logs",
		"column": {
			"id": "Id",
			"userId": "User Id",
			"email": "Email",
			"event": "Event",
			"createdAt": "Created At"
		}
	},
	"instagram": {
		"title": "Instagram Request",
		"menu": "Instagram",
		"dropDown": "Please Select Instagram Service",
		"input": {
			"title": "Name",
			"maxRetry": "Max Retry",
			"url": "Enter Comma Separate Url's"
		},
		"label": {
			"title": "Name",
			"maxRetry": "Max Retry",
			"urlCount": "Url Count",
			"url": "Enter Url's",
			"dropdown": "Select A Instagram Scrapping Service"
		},
		"button": {
			"createInstagram": "Start Scrapping"
		}
	},
	"myInstagram": {
		"view": "My Instagram View",
		"title": "My Instagram",
		"create": "Automate",
		"menu": "Instagram",
		"moveToArchive": "Move to archive",
		"thisWillMoveToArchive": "This will move selected urls to archive.",
		"areYouSureForArchive": "Are you sure you want to archive this urls?",
		"unArchive": "Unarchive urls",
		"thisWillUnArchive": "This will unarchive all selected urls.",
		"areYouSureForUnArchive": "Are you sure you want to unarchive this urls?",
		"no_record_found": "No data? No problem! Automate your data collection and enjoy seamless extraction.",
		"column": {
			"service": "Service",
			"profile": "Profile"
		},
		"input": {
			"title": "Name",
			"maxRetry": "Max Retry",
			"url": "Enter Comma Separate Url's"
		},
		"label": {
			"title": "Name",
			"maxRetry": "Max Retry",
			"urlCount": "Url Count",
			"url": "Enter Url",
			"dropdown": "Select A Instagram Scrapping Service"
		}
	}
}