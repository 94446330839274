import { CommonModule } from '@angular/common';
import {
    HttpClient,
    HttpClientModule
} from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CacheInterceptor } from './http/cache.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { HttpCacheService } from './http/http-cache.service';
import { HttpService } from './http/http.service';
import { I18nService } from './i18n.service';
import { RouteReusableStrategy } from './route-reusable-strategy';

@NgModule({
    imports: [CommonModule, HttpClientModule, TranslateModule, RouterModule],
    providers: [
        I18nService,
        HttpCacheService,
        ErrorHandlerInterceptor,
        CacheInterceptor,
        {
            provide: HttpClient,
            useClass: HttpService
        },
        {
            provide: RouteReuseStrategy,
            useClass: RouteReusableStrategy
        }
    ]
})
export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule
    ) {
        // Import guard
        if (parentModule) {
            throw new Error(
                `${parentModule} has already been loaded. Import Core module in the AppModule only.`
            );
        }
    }
}
