import { EventEmitter, Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { includes } from 'lodash';
import enUS from '../../translations/en-US.json';

const languageKey = 'language';

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param {string} s The string to extract for translation.
 * @return {string} The same string.
 */
export function extract(s: string) {
    return s;
}

@Injectable()
export class I18nService {
    defaultLanguage: string;
    supportedLanguages: string[];
    selectedLanguage = new EventEmitter<string>();
    constructor(private translateService: TranslateService) {
        // Embed languages to avoid extra HTTP requests
        translateService.setTranslation('en-US', enUS);
    }

    /**
     * Initializes i18n for the application.
     * Loads language from local storage if present, or sets default language.
     * @param {!string} defaultLanguage The default language to use.
     * @param {Array.<String>} supportedLanguages The list of supported languages.
     */
    init(defaultLanguage: string, supportedLanguages: string[]) {
        this.defaultLanguage = defaultLanguage;
        this.supportedLanguages = supportedLanguages;
        this.language = '';

        this.translateService.onLangChange.subscribe(
            (event: LangChangeEvent) => {
                localStorage.setItem(languageKey, event.lang);
            }
        );
    }

    /**
     * Sets the current language.
     * Note: The current language is saved to the local storage.
     * If no parameter is specified, the language is loaded from local storage (if present).
     * @param {string} language The IETF language code to set.
     */
    set language(language: string) {
        let setLanguage =
            language ||
            localStorage.getItem(languageKey) ||
            this.translateService.getBrowserCultureLang();
        let isSupportedLanguage = includes(this.supportedLanguages, setLanguage);

        // If no exact match is found, search without the region
        if (setLanguage && !isSupportedLanguage) {
            setLanguage = setLanguage.split('-')[0];
            setLanguage = this.supportedLanguages.find(supportedLanguage =>
                supportedLanguage.startsWith(setLanguage)
            );
            if (!setLanguage) {
                setLanguage = '';
            }
            isSupportedLanguage = Boolean(setLanguage);
        }

        // Fallback if language is not supported
        if (!isSupportedLanguage) {
            setLanguage = this.defaultLanguage;
        }

        this.translateService.use(setLanguage);
    }

    /**
     * Gets the current language.
     * @return {string} The current language code.
     */
    get language(): string {
        return this.translateService.currentLang;
    }
}
