import { Injectable } from '@angular/core';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
declare const heap: any;

@Injectable({
    providedIn: 'root'
})
export class EventLogService {
    constructor() { }
    track(event: string, data?: any) {
        data = _.cloneDeep(data)
        try {
            heap.track(event, data);
        } catch (error) {
            console.error('Heap Track error', error);
        }

        try {
            mixpanel.track(event, data);
        } catch (error) {
            console.error('Mixpanel track error', error);
        }
    }
}
