import {
    HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { from } from 'rxjs';

import { EventLogService } from '../event-log.service';
import { Logger } from '../logger.service';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(
        private eventLogService: EventLogService,
        private injector: Injector
    ) {

    }
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): any {
        return from(this.handle(request, next));

    }

    async handle(req: HttpRequest<any>, next: HttpHandler) {
        // Important: Note the .toPromise()
        const authReq = req.clone();
        return next.handle(authReq).toPromise().catch(async (error) => {
            if (error.status === 401) {
                // using injector to ignore circular DI
                this.eventLogService.track('SESSION_EXPIRED');
            }
            throw error;
        });
    }
}
